import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { virtualTicketAddBet } from '../store/thunks';

interface Props {
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  isDerivate?: boolean;
  b_id: string;
  t_id?: string;
  more?: string;
  // p_oid: string | undefined;
}

const Bet: React.FC<Props> = (props: Props) => {
  const { b_id, t_id, onClick, more } = props;
  const dispatchThunk = useDispatch();
  const bet = useSelector((state: RootState) => state.offer.bets[b_id]);

  const betHandler: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    e.currentTarget.className = e.currentTarget.className + ' odd_pen';

    const code = e.currentTarget.getAttribute('data-code') ?? '';
    const oid = e.currentTarget.getAttribute('data-oid') ?? '';
    const oddv = e.currentTarget.getAttribute('data-oddv') ?? '';
    dispatchThunk(virtualTicketAddBet(code, oid, oddv, b_id));
  };

  const tooManyOdds: boolean = (bet?.odds.length ?? 0) > 6;
  const dateAndTime = tooManyOdds ? null : (
    <>
      <div className='tdate'>{bet?.date}</div>
      <div className='tdate'>{bet?.time}</div>
    </>
  );
  if (bet === undefined) {
    return null;
  }
  return (
    <div className='bet'>
      <div className='bcode'>{bet.code}</div>
      <div
        className='bname'
        data-tid={t_id}
        data-bid={bet.id}
        onClick={onClick}
      >
        {bet.name}
      </div>

      {bet.odds.slice(0, 9).map((odd, i) => (
        <b
          className={`odd ${odd.v.indexOf(' ') >= 0 ? 'hidden' : ''}${
            odd.sel ? 'sel_odd' : ''
          }`}
          key={odd.id}
          data-bid={b_id}
          data-code={bet.code}
          data-oid={odd.id}
          data-oddv={odd.v}
          onClick={betHandler}
        >
          {odd?.v ?? ''}
        </b>
      ))}

      <div className='inline-block flex-grow flex-shrink flex-auto'>&nbsp;</div>
      {more !== undefined && (
        <div className='more' onClick={onClick}>
          {more}
        </div>
      )}

      {dateAndTime}
    </div>
  );
};

export default Bet;
