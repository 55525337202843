// import { Props } from "lib/data";
import { useEffect, useRef, useState } from 'react';
import useMovieStore from '../store/movieStore';
import { PlaylistInfo } from '../store/settings/types';

export const getScreenSaverURL = (playlist?: PlaylistInfo): string => {
  const defaultURL =
    'https://api.darsery.app/api/pool/download/oregon/video?v=CP_Paperless_Journey_FINAL_C18-1659098287027.mp4';
  try {
    if ((playlist?.video?.url ?? '').length > 0) {
      return playlist?.video.url ?? defaultURL;
    }
  } catch (err) {
    console.error('error parsing screensaver config');
    console.error(err);
    return defaultURL;
  }
  return defaultURL;
};

interface Props {
  isTouchscreen: boolean;
  onInteraction: () => void;
}
const CachedMoviePlayer: React.FC<Props> = (props) => {
  const { isTouchscreen, onInteraction } = props;

  const [cachedURL, setCachedURL] = useState<string | undefined>(undefined);
  let getMediaFromCache = useMovieStore((state) => state.getMediaFromCache);
  const ref = useRef<HTMLVideoElement>(null);
  //   const playMuted = useMovieStore((state) => state.playMuted);
  //   const setPlayMuted = useMovieStore((state) => state.setPlayMuted);

  useEffect(() => {
    const src = getScreenSaverURL();
    let interval = setInterval(() => {
      console.log('Cached Movie Player, retry');
      getMediaFromCache(src).then((url) => {
        if (!url) {
          console.log('Movie is stopped. Missing cachedURL');
        } else {
          console.log(`Movie found cached url ${url}`);
          setCachedURL(url!);
          clearInterval(interval);
        }
      });
    }, 1000);
    return () => {
      console.log('Cached Movie Player, gone');
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      onClick={onInteraction}
      onTouchStart={onInteraction}
      onPointerDown={onInteraction}
      onDragStart={onInteraction}
      className='fixed w-screen h-screen z-[999999999]'
    >
      {cachedURL && (
        <video
          ref={ref}
          width={isTouchscreen ? 1920 : 1280}
          height={isTouchscreen ? 1080 : 800}
          src={cachedURL}
          autoPlay
          playsInline
          loop
          muted={true}
          controls={false}
          onError={(error) => {
            console.log('Movie Error', error);
          }}
          onEnded={() => {
            console.log('Movie Ended. Should play again');
            //
          }}
          onLoadedMetadata={async () => {
            console.log('Movie Loaded Metadata');
          }}
        />
      )}
    </div>
  );
};
export default CachedMoviePlayer;
