import { useState, useEffect, useRef } from 'react';
interface Props {
  url: string;
  width: number;
  height: number;
}
const TicketMoviePlayer: React.FC<Props> = (props) => {
  const { url, width, height } = props;
  const [loopCount, setLoopCount] = useState(0);
  const [timeUpdate, setTimeUpdate] = useState(-1);
  const timer = useRef(0.0);

  const player = useRef<HTMLVideoElement | null>(null);

  // const src = `${process.env.PUBLIC_URL}/media/${
  //   isTouchscreen ? 'touchscreen20210427.mp4' : 'tablet20210427.mp4'
  // }`;

  useEffect(() => {
    setTimeUpdate(-1);
    player.current?.play();
  }, [loopCount]);

  useEffect(() => {
    if (timeUpdate === -1) {
      window.clearTimeout(timer.current);
    } else {
      timer.current = window.setTimeout(fixVideo, 3000);
    }
    return () => {
      window.clearTimeout(timer.current);
    };
  }, [timeUpdate]);

  const fixVideo = () => {
    console.warn('Video is not playing. Will force a video reload.');
    player.current?.load();
  };

  return (
    <video
      ref={player}
      width={width}
      height={height}
      muted
      className='ticket-video'
      autoPlay
      controls={false}
      // preload='auto'
      onPlaying={(e) => {
        setTimeUpdate(0);
      }}
      onError={(e) => {
        setTimeUpdate(0);
      }}
      onEnded={(e) => {
        setLoopCount(loopCount + 1);
      }}
      onTimeUpdate={(e) => {
        setTimeUpdate(timeUpdate + 1);
      }}
    >
      <source src={url} type='video/mp4' />
    </video>
  );
};
export default TicketMoviePlayer;
