import { useSelector } from 'react-redux';
import App from './App';
import AppBootloader from './AppBootloader';
import { RootState } from './store';

const AppWithCode: React.FC = () => {
  const isRegistered = useSelector<RootState>(
    (state) => `${state.settings?.identity?._id ?? ''}`.length >= 6
  );
  return (
    <>
      {isRegistered && <App />}
      {!isRegistered && <AppBootloader />}
    </>
  );
};

export default AppWithCode;
