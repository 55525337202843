import React from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../store';

import Bet from './Bet';

interface OwnProps {
  typeId: string;
  betsIds: string[];
}

const MarketTable: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const { betsIds, typeId, betType } = props;

  const tooManyOdds: boolean = betType.odds.length > 6;
  const dateAndTime = tooManyOdds ? null : (
    <>
      <div className='t-tdate'>DATA</div> <div className='t-tdate'>ORA</div>
    </>
  );

  return (
    <React.Fragment key={typeId}>
      <div className='t-hc'>
        <div className='t-h'>
          <div className='t-title'>{betType.name}</div>

          {betType.odds.slice(0, 9).map((odd, i) => (
            <b className='t-oname tint' key={i}>
              {odd}
            </b>
          ))}
          <div className='inline-block flex-grow flex-shrink flex-auto'>
            &nbsp;
          </div>
          {dateAndTime}
        </div>
        {betsIds.length > 0 && <Bet key={betsIds[0]} b_id={betsIds[0]} />}
      </div>
      {betsIds.map((b, bi) => (bi > 0 ? <Bet key={b} b_id={b} /> : null))}
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const { offer } = state;
  const { typeId, betsIds } = ownProps;
  const type = offer.types[typeId];

  return {
    typeId: ownProps.typeId,
    betsIds,
    betType: type,
  };
};

const mapDispatchActions = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    dispatch,
  };
};

const connector = connect(mapStateToProps, mapDispatchActions);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MarketTable);
