/** @format */

// import { v4 } from 'uuid';

import {
  ActionTypes,
  INIT_APP,
  RECEIVE_INITIAL_DATA,
  UPDATE_IS_CHECKING_TICKET,
  START_SESSION,
  TERMINATE_SESSION,
  PLAY_MOVIE,
  STOP_MOVIE,
  SHOW_WHEEL,
  HIDE_WHEEL,
} from './types';

type SystemState = {
  loaded: boolean;
  connected: boolean;
  isCheckingTicket: boolean;
  isPlayingMovie: boolean;
  isShowingWheel: boolean;
};

export const initialState: SystemState = {
  loaded: false,
  connected: false,
  isCheckingTicket: false,
  isPlayingMovie: false,
  isShowingWheel: false,
};

export const systemReducer = (
  state: SystemState = initialState,
  action: ActionTypes
): SystemState => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        loaded: false,
        connected: false,
        isCheckingTicket: false,
      };

    case RECEIVE_INITIAL_DATA:
      return {
        ...state,
        loaded: true,
      };
    case UPDATE_IS_CHECKING_TICKET:
      return {
        ...state,
        isCheckingTicket: action.meta.value,
      };
    case START_SESSION:
      return {
        ...state,
        isPlayingMovie: false,
        isShowingWheel: false,
      };

    case TERMINATE_SESSION:
      return {
        ...state,
        isCheckingTicket: false,
        isShowingWheel: false,
        isPlayingMovie: true,
      };

    case STOP_MOVIE:
      return {
        ...state,
        isPlayingMovie: false,
      };
    case SHOW_WHEEL:
      return {
        ...state,
        isShowingWheel: true,
      };
    case HIDE_WHEEL:
      return {
        ...state,
        isShowingWheel: false,
      };
    default:
      return state;
  }
};
