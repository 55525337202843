/** @format */

import { wsMessage } from '../system/actions';
import {
  WS_MESSAGE,
  WS_CONNECTED,
  WS_DISCONNECT,
  ActionTypes as SystemActionTypes,
} from '../system/types';
import {
  RESET_OFFER,
  CHANGE_SELECTED_DATE,
  CHANGE_SELECTED_CATEGORY,
  SHOW_TOURNAMENT_DETAILS,
  HIDE_TOURNAMENT_DETAILS,
  ActionTypes as OfferActionTypes,
} from '../offer/types';
import { RootState } from '..';

type AllActions = SystemActionTypes | OfferActionTypes;

const logger = (store: any) => (next: any) => (action: AllActions) => {
  const start = performance.now();
  const result = next(action);
  const end = performance.now();
  const diff = Math.round(end - start);

  const { _id } = store.getState().settings!.identity;
  const { offer } = store.getState() as RootState;

  switch (action.type) {
    case WS_MESSAGE:
      return;

    case WS_CONNECTED:
      store.dispatch(
        wsMessage({
          appId: _id,
          isTouchscreen: true,
          rx_action: action,
          dt: diff,
        })
      );
      return;
    case WS_DISCONNECT:
      store.dispatch(
        wsMessage({
          appId: _id,
          isTouchscreen: true,
          rx_action: action,
          dt: diff,
        })
      );
      break;
    case CHANGE_SELECTED_DATE:
      {
        let c_id: string | undefined;
        let c_name: string | undefined;
        try {
          c_id = action.meta.category;
          c_name = offer.categories[c_id].name;
        } catch (err) {
          console.warn(err);
        }

        store.dispatch(
          wsMessage({
            appId: _id,
            isTouchscreen: true,

            rx_action: {
              type: action.type,
              date: action.meta.date,
              c_id,
              c_name,
            },
            dt: diff,
          })
        );
      }
      break;
    case CHANGE_SELECTED_CATEGORY:
      {
        const c_id = action.meta.category;
        const c_name = offer.categories[c_id].name;
        const date = offer.selectedDate;
        store.dispatch(
          wsMessage({
            appId: _id,

            isTouchscree: true,
            rx_action: {
              type: action.type,
              c_id,
              c_name,
              date,
            },
            dt: diff,
          })
        );
      }
      break;

    case SHOW_TOURNAMENT_DETAILS:
      {
        const c_id = offer.selectedCategory ?? '';
        const c_name = offer.categories[c_id].name;
        const date = offer.selectedDate;
        const t_id = action.meta.tournament_id;
        const t_name = offer.tournaments[t_id].name;
        const b_id = action.meta.bet_id;
        const b_name = b_id ? offer.bets[b_id].name : undefined;
        store.dispatch(
          wsMessage({
            appId: _id,

            isTouchscreen: true,
            rx_action: {
              type: action.type,
              c_id,
              c_name,
              t_id,
              t_name,
              b_id,
              b_name,
              date,
            },
            dt: diff,
          })
        );
      }
      break;
    case HIDE_TOURNAMENT_DETAILS:
      store.dispatch(
        wsMessage({
          appId: _id,

          isTouchscreen: true,
          rx_action: {
            type: action.type,
          },
          dt: diff,
        })
      );

      break;

    case RESET_OFFER:
      store.dispatch(
        wsMessage({
          appId: _id,
          isTouchscreen: true,
          rx_action: { type: action.type },
          dt: diff,
        })
      );
      break;
    case undefined:
      return;
    default:
      break;
  }
  return result;
};

export default logger;
