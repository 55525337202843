import { Settings } from './store/settings/types';
import axios from 'axios';
const fakeSettings = process.env.REACT_APP_SETTINGS as string | undefined;

export const fetchSettings: (
  forced: boolean,
  code?: string
) => Promise<false | Settings> = async (forced, code) => {
  if (fakeSettings) {
    console.log('USING DUMMY SETTINGS');
    return JSON.parse(fakeSettings) as Settings;
  }

  let result = await axios.get(
    `http://localhost:8080/auth/settings?forced=${forced ? 1 : 0}&${
      code ? '&code=' + code : ''
    }`,
    { timeout: 4000 }
  );
  if (result.status === 204) {
    console.log('status 204');
    return false;
  }
  console.log('status 200 json');
  return result.data as Settings;
};
