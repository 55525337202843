import React, {
  // useState,
  // useLayoutEffect,
  useEffect,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { debounce } from 'lodash';
import { RootState } from '../store';

import * as selectors from '../store/selectors';
import TournamentTable from './TournamentTable';
import { wsMessage } from '../store/system/actions';
import { motion } from 'framer-motion';
import TournamentNavigator from './TournamentNavigator';
import VerticalScrollButtons from './ScrollButtons/VerticalScrollButtons';
interface Props extends PropsFromRedux {
  isTouchscreen: boolean;
}
type TargetWithValue = {
  value: string;
};

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const OfferContent: React.FC<Props> = (props: Props) => {
  const { tournaments, category, dispatch, appId, date } = props;
  const categoryName = category?.name.toUpperCase() || '';
  const ul = React.useRef<HTMLDivElement>(null);
  const [canScrollDown, setCanScrollDown] = useState(false);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const lastScroll = React.useRef(0);

  const logScroll = useCallback(
    (top: number) => {
      console.log('scrolling');
      if (top === lastScroll.current) {
        return;
      }

      lastScroll.current = top;
      dispatch(
        wsMessage({
          appId,

          isTouchscreen: true,
          rx_action: {
            type: 'WILL_SCROLL',
            top,
            c_id: category?.id,
            c_name: category?.name,
            date,
          },
          dt: 0,
        })
      );
    },
    [lastScroll, appId, dispatch, category, date]
  );

  const debouncedLogTAScroll = useCallback(debounce(logScroll, 300), [
    logScroll,
  ]);

  const onDidScroll = useCallback(
    (e?: Event) => {
      if (!ul.current) {
        return;
      }

      const top = ul.current.scrollTop;
      debouncedLogTAScroll(top);
    },
    [debouncedLogTAScroll]
  );

  useEffect(() => {
    const container = ul.current;

    container?.scrollTo({ top: 0 });
  }, [tournaments]);

  useEffect(() => {
    const container = ul.current;

    container?.addEventListener('scroll', onDidScroll);
    return () => {
      container?.removeEventListener('scroll', onDidScroll);
    };
  }, [logScroll, appId, onDidScroll]);

  /** Scroll */
  const tryScroll = (up: boolean) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;
    const h = theul.offsetHeight;
    const options: ScrollToOptions = { behavior: 'smooth' };
    options.top = h * (up ? -1 : 1);

    theul.scrollBy(options);
  };
  const handleScroll: EventListener = (e) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;

    const ot = theul.scrollTop;
    const sh = theul.scrollHeight;
    const h = theul.offsetHeight;

    setCanScrollUp(ot > 0);
    setCanScrollDown(ot + h < sh);
  };

  useLayoutEffect(() => {
    console.log('Tournaments navigator scroll to top');
    ul.current?.scrollTo({ top: 0 });
  }, [tournaments]);

  useEffect(() => {
    const thisul = ul.current;

    thisul?.addEventListener('scroll', handleScroll);
    handleScroll(new MouseEvent('scroll'));
    return () => {
      thisul?.removeEventListener('scroll', handleScroll);
    };
  }, [tournaments]);

  const handleScrollToTournament = (tournament_id: string) => {
    const th = document.querySelector<HTMLElement>(
      `div.t-h[data-tid="${tournament_id}"]`
    );
    const options: ScrollToOptions = {
      behavior: 'smooth',
    };
    options.top = th?.offsetTop ?? 0;

    document.querySelector('.content-wrapper')?.scrollTo(options);
  };

  return (
    <>
      <TournamentNavigator
        isTouchscreen={false}
        onScroll={handleScrollToTournament}
      />
      <motion.div
        ref={ul}
        className='tablet content-wrapper'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {tournaments.map((t) => (
          <TournamentTable tid={t} key={t} categoryName={categoryName} />
        ))}
      </motion.div>
      {(canScrollDown || canScrollUp) && (
        <VerticalScrollButtons
          onScroll={tryScroll}
          canScrollDown={canScrollDown}
          canScrollUp={canScrollUp}
          className='fixed flex left-[1264px] bottom-[20px]'
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const category = selectors.getSelectedCategory(state);
  const date = state.offer.selectedDate;

  let tournaments: string[] = [];
  if (!(!category || !date)) {
    tournaments = category.tournamentsByDate[date];
  }

  return {
    tournaments,
    category,
    appId: state.settings!.identity._id,

    date,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return { dispatch };
};
const connector = connect(mapStateToProps, mapDispatchActions);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OfferContent);
