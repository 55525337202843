import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

import VirtualTicketBetRow from './VirtualTicketBetRow';
import ConnectedVirtualTicketEditorHeader from './VirtualTicketEditorHeader';
import EmptyVirtualTicketBody from './EmptyVirtualTicketBody';
import VirtualTicketEditorControls from './VirtualTicketEditorControls';
import VerticalTicketFooter from './VerticalTicketFooter';
import { virtualTicketPrepare } from '../../store/thunks';
const ticketServerStateSelector = (state: RootState) => {
  const {
    virtualTicket: { ticketServerState },
  } = state;
  const {
    bets,
    codes,
    oids,
    ticket_mode,
    messages,
    castig,
    miza,
    legal,
    totalOddString,
    totalOdd,
    groups,
    shortCode,
    systemGroupBets,
    combiGroupBets,
  } = ticketServerState;
  return {
    bets,
    codes,
    oids,
    ticket_mode,
    messages,
    castig,
    miza,
    legal,
    totalOddString,
    totalOdd,
    groups,
    shortCode,
    systemGroupBets,
    combiGroupBets,
  };
};

const VerticalVirtualTicket: React.FC = () => {
  const {
    bets,
    codes,
    oids,
    ticket_mode,
    messages,
    castig,
    miza,
    legal,
    totalOddString,
    totalOdd,
    groups,
    shortCode,
    systemGroupBets,
    combiGroupBets,
  } = useSelector(ticketServerStateSelector);

  const isEmptyTicket = oids.length === 0;
  const dispatch = useDispatch();

  const prepareTicket = () => {
    if (shortCode) {
      return;
    }
    dispatch(virtualTicketPrepare());
  };
  return (
    <div className='ticket-editor'>
      <ConnectedVirtualTicketEditorHeader />
      <div
        className={
          'flex flex-col justify-between ' +
          (isEmptyTicket ? 'h-[815px]' : 'h-[759px]')
        }
      >
        {isEmptyTicket === true && (
          <>
            <EmptyVirtualTicketBody />
          </>
        )}
        {isEmptyTicket === false && (
          <div className='ticket-body overflow-y-scroll overflow-x-hidden'>
            {ticket_mode !== 'COMBI' && (
              <>
                {oids.map((oid, index) => (
                  <VirtualTicketBetRow
                    key={oid}
                    {...bets[oid]}
                    editable={true}
                    mode={ticket_mode}
                    roundedTop={index !== 0}
                  />
                ))}
                {ticket_mode === 'COMBI' && (
                  <>
                    {groups.map((group) => (
                      <div key={group.name} className='mb-24'>
                        <div
                          className='h-36 lh-36 text-11 text-white semibold flex mb-1'
                          style={{ marginRight: 54 }}
                        >
                          <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                            <span>
                              GRUP{' '}
                              <span className='text-yellow'>{group.name}</span>
                            </span>
                            <span>
                              {group.bets.length}{' '}
                              {group.bets.length !== 1 ? 'Pariuri' : 'Pariu'}
                            </span>
                          </div>
                          <div
                            className='inline-flex space-between bg-gray-gradient rounded-t px-12 box-border'
                            style={{ width: 111 }}
                          >
                            <span>Cota</span>
                            <span className='text-yellow'>{group.odd}</span>
                          </div>
                        </div>

                        {group.bets.map((oid, index) => (
                          <VirtualTicketBetRow
                            key={oid}
                            {...bets[oid]}
                            editable={true}
                            roundedTop={index !== 0}
                          />
                        ))}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
            <VirtualTicketEditorControls
              miza={miza}
              castig={castig}
              totalOdd={totalOdd}
              totalOddString={totalOddString}
              legal={legal}
              shortCode={shortCode}
              systemGroupBets={systemGroupBets}
              ticket_mode={ticket_mode}
              combiGroupBets={combiGroupBets}
            />
          </div>
        )}
        {isEmptyTicket === false && (
          <VerticalTicketFooter
            messages={messages}
            shortCode={shortCode}
            prepareTicket={prepareTicket}
          />
        )}
      </div>
    </div>
  );
};
export default VerticalVirtualTicket;
