import React from 'react';

interface FullScreenMessageBoxProps {
  message: string;
  onCancel: () => void;
  cancelLabel: string;
  onContinue: () => void;
  continueLabel: string;
}
const FullScreenMessageBox: React.FC<FullScreenMessageBoxProps> = (props) => {
  const { message, onCancel, cancelLabel, onContinue, continueLabel } = props;
  return (
    <div className='fs-bg-shadow fixed top-0 left-0 w-screen h-screen z-50 items-center flex justify-center'>
      <div className='inline-block bg-dark px-48 py-24 rounded-4'>
        <div className='text-white text-center text-14 semibold'>{message}</div>
        <div className='text-center mt-32'>
          <div
            className='inline-block h-44 lh-44 w-100 text-center text-13 bold bg-gray-500 mr-16 rounded-4'
            onClick={onCancel}
          >
            {cancelLabel}
          </div>
          <div
            className='inline-block h-44 lh-44 w-100 text-center text-13 bold bg-yellow rounded-4'
            onClick={onContinue}
          >
            {continueLabel}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullScreenMessageBox;
