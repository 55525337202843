import {
  ActionTypes,
  SettingsState,
  SET_SETTINGS,
  UPDATE_CONFIG,
  UPDATE_TOKEN,
} from './types';
export const initialState: SettingsState = {
  identity: { _id: '', code: '', shop: '', brand: '', test: false },
  auth: { token: '', _id: '' },
  config: {
    ticketsAPI: '/',
    wssAPI: '/',
    idleTimeoutMovie: 180000,
    printingAPI: '/',
    printingMode: 'none',
    printerHostname: '',
    playlist: undefined,
  },
};

export const settingsReducer = (
  state: SettingsState = initialState,
  action: ActionTypes
): SettingsState => {
  switch (action.type) {
    case SET_SETTINGS: {
      return action.meta.settings;
    }
    case UPDATE_CONFIG: {
      return {
        ...(state! as SettingsState),
        config: action.meta.config,
      };
    }
    case UPDATE_TOKEN:
      return {
        ...(state! as SettingsState),
        auth: { ...(state! as SettingsState).auth, token: action.meta.token },
      };
    default:
      return state;
  }
};

export type AppState = SettingsState;
