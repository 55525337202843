const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <svg width='100%' height='4px'>
      <rect fill='#111111' x={0} y={0} width='100%' height='100%' ry='50%' />
      <rect
        fill='#EDE93F'
        x={0}
        y={0}
        width={`${progress}%`}
        height='100%'
        ry='50%'
      />
    </svg>
  );
};

export default ProgressBar;
