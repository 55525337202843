import ProgressBar from '../components/ProgressBar';
import { ReactComponent as LogoCasa } from '../img/logo_casa.svg';
const LoadingScreen: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <>
      <div className='bg-black flex flex-col items-center justify-start h-screen'>
        <div className='inline-block h-1/4'>&nbsp;</div>
        <div className='inline-block'>
          <LogoCasa />
        </div>
        <div className='inline-block h-1/4'>&nbsp;</div>
        <div className='inline-block text-front-primary text-lg font-semibold'>
          Oferta se pregătește
        </div>
        <div className='inline-block w-[200px] mt-10'>
          <ProgressBar progress={progress} />
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
