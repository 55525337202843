/** @format */

import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { throttle } from 'lodash';
import { useAppDispatch } from './store';
import {
  setInitialDate,
  showScanTicketThunk,
  startNewSession,
  thunkInitApp,
  updateOffer,
} from './store/thunks';
import { RootState } from './store';
// import { reloadApp } from './hardware';
import Loading from './components/Loading';
import Offer from './components/Offer';
import './App.scss';
import TournamentDetails from './components/TournamentDetails';
import VirtualTickets from './components/VirtualTickets';
import CheckTicket from './components/CheckTicket/CheckTicket';

import { useWindowSize } from './components/CustomHooks';
import MoviePlayer from './components/MoviePlayer';
import {
  hideWheel,
  startSession,
  terminateSession,
  updateIsCheckingTicket,
  wsMessage,
} from './store/system/actions';
import LoadingScreen from './pages/LoadingScreen';
import { resetAllTickets } from './store/ticket/ticket-actions';
import { useQuery } from 'react-query';
import { fetchSettings } from './pixel_api';
import WheelOfFortune from './pages/WheelOfFortune';
import CachedMoviePlayer from './components/CachedMoviePlayer';

const INACTIVITY_TIMEOUT = Number(process.env.REACT_APP_INACTIVITY_TIMEOUT);
const MOVIE_LOOP_MAX = Number(process.env.REACT_APP_MOVIE_LOOP_MAX);

interface TicketBarcode {
  code: string;
  pin: string;
  virtual: boolean;
}

const App: React.FC = () => {
  const [idle, setIdle] = React.useState(0);
  const timer = React.useRef<number | undefined>(undefined);
  const updateOfferTimer = React.useRef<number | undefined>(undefined);
  const [scannedTicket, setScannedTicket] = React.useState<
    TicketBarcode | undefined
  >(undefined);

  const code = useSelector<RootState>(
    (state) => state.settings.identity._id
  ) as string;

  const { data: settings } = useQuery(
    ['/settings'],
    () => fetchSettings(false, code),
    {
      retry: true,
      refetchInterval: 5 * 60 * 1000,
    }
  );
  const dispatch = useAppDispatch();

  useEffect(() => {}, [settings]);
  const barcode = useRef('');

  const {
    showTournamentDetails,
    loaded,
    isCheckingTicket,
    appId,
    isPlayingMovie,
    isShowingWheel,
  } = useSelector((state: RootState) => {
    return {
      showTournamentDetails: state.offer.selectedTournament,
      loaded: state.system.loaded,
      isCheckingTicket: state.system.isCheckingTicket,
      appId: state.settings!.identity._id,
      isPlayingMovie: state.system.isPlayingMovie,
      isShowingWheel: state.system.isShowingWheel,
    };
  }, shallowEqual);

  const size = useWindowSize();
  const isTouchscreen = size.isTouchScreen;
  useEffect(() => {
    dispatch(thunkInitApp());
  }, [dispatch]);

  const endSessionIfNeeded = () => {
    if (isPlayingMovie === true) {
      console.log('endSessionIfNeeded IGNORED, isPlayingMovie');
      window.clearTimeout(timer.current);
      return;
    }
    console.log('endSessionIfNeeded ending session');
    dispatch(terminateSession());
    dispatch(resetAllTickets());
    dispatch(updateOffer());
  };

  useEffect(() => {
    console.log('idle changed useEffect setTimeout for endSessionIfNeeded');
    window.clearTimeout(timer.current);
    timer.current = window.setTimeout(endSessionIfNeeded, INACTIVITY_TIMEOUT);
    return () => {
      window.clearTimeout(timer.current);
    };
  }, [idle]);

  const updateOfferIfNeeded = () => {
    console.log('update offer in background:');
    if (isPlayingMovie === false) {
      return;
    }
    dispatch(updateOffer());
  };

  useEffect(() => {
    if (isPlayingMovie === true) {
      console.debug('enable offer background update');
      updateOfferTimer.current = window.setInterval(
        updateOfferIfNeeded,
        5 * 60 * 1000 // every 5 minutes
      );
    } else {
      console.debug('disable offer background update');
      window.clearInterval(updateOfferTimer.current);
    }
  }, [isPlayingMovie]);

  const updateIdle = (event: Event | undefined) => {
    console.log('*******updateIdle');
    setIdle(new Date().getTime());
  };
  const throttledUpdateidle = throttle(updateIdle, 1000);

  const handleKeyDown = (e: KeyboardEvent) => {
    // console.log(
    //   `New key ${e.key}, shift: ${e.shiftKey} - barcode was ${barcode.current} length: ${barcode.current.length}`
    // );

    if (e.key === 'Enter') {
      console.log(
        `Enter Key: barcode is ${barcode.current} length: ${barcode.current.length}`
      );
      throttledUpdateidle(undefined);
      document.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        })
      );
      switch (barcode.current.length) {
        case 20: {
          console.info('Barcode scanned');
          const code = barcode.current;
          barcode.current = '';
          const scannedTicket = {
            code: code.substring(0, 16),
            pin: code.substring(16),
            virtual: false,
          };
          console.log(
            `Found barcode ${scannedTicket.code} ${scannedTicket.pin}`
          );
          setScannedTicket(scannedTicket);
          dispatch(showScanTicketThunk());
          break;
        }
        case 13: {
          console.info('Virtual Games Barcode scanned');
          const code = barcode.current.substring(0, 9);
          barcode.current = '';
          console.log(`Found barcode ${code}`);
          const scannedTicket = {
            code: code,
            pin: '',
            virtual: true,
          };
          setScannedTicket(scannedTicket);
          dispatch(showScanTicketThunk());
          break;
        }
        case 9: {
          console.info('Virtual Games Barcode scanned');
          const code = barcode.current;
          barcode.current = '';
          console.log(`Found barcode ${code}`);
          const scannedTicket = {
            code: code,
            pin: '',
            virtual: true,
          };

          setScannedTicket(scannedTicket);
          dispatch(showScanTicketThunk());
          break;
        }

        default: {
          barcode.current = '';
        }
      }
    } else {
      if (e.key.length === 1 && e.key.match(RegExp('[0-9A-z]'))) {
        barcode.current += `${e.key}`;
      }
    }
  };

  const handleWindowMessage = useCallback((event: MessageEvent) => {
    console.log(event);
    console.log(event.data);
    if (event.data === 'MSG_WHEEL_INTERACTION') {
      throttledUpdateidle(undefined);
    }
    if (event.data === 'MSG_WHEEL_FINISHED') {
      dispatch(hideWheel());
    }
  }, []);
  useEffect(() => {
    if (!loaded) {
      return () => {
        console.log(2 + 2);
      };
    }

    window.addEventListener('message', handleWindowMessage);
    document.addEventListener('click', throttledUpdateidle);
    // document.addEventListener('click', throttledUpdateidle);
    document.addEventListener('touchmove', throttledUpdateidle);
    document.addEventListener('onmousedown', throttledUpdateidle);
    document.addEventListener('mousewheel', throttledUpdateidle);
    document.addEventListener('touchstart', throttledUpdateidle);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', throttledUpdateidle);
      document.removeEventListener('touchmove', throttledUpdateidle);
      document.removeEventListener('onmousedown', throttledUpdateidle);
      document.removeEventListener('mousewheel', throttledUpdateidle);
      document.removeEventListener('touchstart', throttledUpdateidle);
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [loaded]);

  if (loaded)
    return (
      <>
        {isShowingWheel && <WheelOfFortune />}
        {isPlayingMovie && (
          <CachedMoviePlayer
            isTouchscreen={size.isTouchScreen}
            onInteraction={() => {
              console.debug('New session started');
              dispatch(startNewSession());
              dispatch(setInitialDate());

              setIdle(new Date().getTime());
            }}
          />
        )}

        <>
          <Offer
            isTouchscreen={size.isTouchScreen}
            onShowNewCheckTicket={() => setScannedTicket(undefined)}
          />
          {isCheckingTicket && (
            <CheckTicket
              isTouchScreen={size.isTouchScreen}
              receivedProtectionCode={scannedTicket?.pin}
              receivedTicketId={scannedTicket?.code}
              receivedTicketIsVirtual={scannedTicket?.virtual}
            />
          )}
          {showTournamentDetails && (
            <TournamentDetails isTouchscreen={size.isTouchScreen} />
          )}
          <VirtualTickets />
        </>
      </>
    );
  return <LoadingScreen progress={80} />;
};

export default App;
