/** @format */

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import App from './App';

import * as serviceWorker from './serviceWorker';
import './fonts/fonts.css';

import { QueryClient, QueryClientProvider } from 'react-query';
// import App from './App';

const queryClient = new QueryClient();
import './App.scss';
import AppWithCode from './AppWithCode';
import { store } from './store';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AppWithCode />

        {/* <App /> */}
      </Provider>
    </QueryClientProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
