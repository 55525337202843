/** @format */
import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  systemReducer,
  initialState as initialSystemState,
} from './system/reducers';
import {
  offerReducer,
  initialState as initialOfferState,
} from './offer/reducers';

import {
  settingsReducer,
  initialState as initialSettingsState,
} from './settings/index';

import * as StoreTypes from './types';
import * as SystemActions from './system/actions';
import * as OfferActions from './offer/actions';
import {
  ticketReducer,
  initialState as initialVirtualTicketState,
} from './ticket/ticket-reducers';
import configureStore from './configureStore';

export const rootReducer = combineReducers({
  system: systemReducer,
  offer: offerReducer,
  virtualTicket: ticketReducer,
  settings: settingsReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export const initialState: RootState = {
  settings: initialSettingsState,
  system: initialSystemState,
  offer: initialOfferState,
  virtualTicket: initialVirtualTicketState,
};

export { StoreTypes, SystemActions, OfferActions };
export const store = configureStore();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
