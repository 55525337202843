import React, { SyntheticEvent, useLayoutEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../store';

import { Category } from '../store/offer/types';
import EOButton from './EOButton';
import { changeSelectedCategory } from '../store/offer/actions';
import * as selectors from '../store/selectors';
import VerticalScrollButtons from './ScrollButtons/VerticalScrollButtons';

type AppProps = {
  children?: React.ReactChildren;
  onChangeCategory: (category: string) => void;
  selectedCategory?: string | undefined;
  categories: Array<Category>;
  isTouchscreen: boolean;
};

type TargetWithValue = {
  value: string;
};

const CategoryFilter: React.FC<AppProps> = (props: AppProps) => {
  const { categories, onChangeCategory, selectedCategory } = props;
  const ul = React.useRef<HTMLUListElement>(null);
  const [canScrollDown, setCanScrollDown] = React.useState(false);
  const [canScrollUp, setCanScrollUp] = React.useState(false);
  const changeCategory = (e: SyntheticEvent<TargetWithValue>) => {
    onChangeCategory(e.currentTarget.value);
  };

  const onDidScroll: EventListener = (e) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;

    const ot = theul.scrollTop;
    const sh = theul.scrollHeight;
    const h = theul.offsetHeight;

    setCanScrollUp(ot > 0);
    setCanScrollDown(ot + h < sh);
  };

  React.useEffect(() => {
    const container = ul.current;
    container?.scrollTo({ top: 0 });
  }, [categories]);

  const tryScroll = (up: boolean) => {
    if (!ul.current) {
      return;
    }
    const theul = ul.current;
    const h = theul.offsetHeight;
    const options: ScrollToOptions = { behavior: 'smooth' };
    options.top = h * (up ? -1 : 1);

    theul.scrollBy(options);
  };

  useLayoutEffect(() => {
    console.log('Category filter scroll to top');
    ul.current?.scrollTo({ top: 0 });
  }, [categories]);

  return (
    <>
      <ul ref={ul} className='scrollable'>
        {categories.map((cat: Category) => (
          <li key={cat.id}>
            <EOButton
              value={cat.id}
              selected={`${cat.id}` === selectedCategory}
              title={cat.name}
              onClick={changeCategory}
              width='100%'
            />
          </li>
        ))}
      </ul>
      {(canScrollDown || canScrollUp) && (
        <VerticalScrollButtons
          onScroll={tryScroll}
          canScrollDown={canScrollDown}
          canScrollUp={canScrollUp}
          className='fixed flex left-[84px] bottom-[20px]'
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    categories: selectors.getCategoriesInSelectedDay(state),
    selectedCategory: selectors.getSelectedCategoryId(state),
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    onChangeCategory: (category: string) => {
      dispatch(changeSelectedCategory(category));
    },
  };
};

export default connect(mapStateToProps, mapDispatchActions)(CategoryFilter);
