import {
  ActionType,
  CHANGE_CURRENT_PREPARED_TICKET,
  DELETE_PREPARED_VIRTUAL_TICKET,
  DELETE_VIRTUAL_TICKET_MESSAGE,
  RESET_TICKET,
  SEND_TICKETS_TO_APP_LOADING,
  SEND_TICKETS_TO_APP_COMPLETE,
  SEND_TICKETS_TO_APP_ERROR,
  SEND_TICKETS_TO_APP_RESET,
  SHOW_PREPARED_TICKETS,
  START_SESSION,
  TICKET_TOGGLE_EXPANDED,
  UPDATE_TICKETS,
  RESET_ALL_TICKETS,
  VirtualTicketMachine,
} from './ticket-types';

export const initialState: VirtualTicketMachine = {
  cookies: undefined,
  isVisible: true,
  isExpanded: false,
  currentTicket: 1,

  currentPreparedTicket: undefined,
  isShowingPreparedTickets: false,
  codes: [],
  ticketServerState: {
    activeId: 1,
    data_ids1: [],
    data_ids2: [],
    data_ids3: [],
    data_ids4: [],
    totalOdd: 1,
    totalOddString: '1.00',
    miza: 1,
    castig: 1,
    legal: '',
    ticket_mode: 'AKO',
    ticket_mode_info: 'SIMPLU',
    codes: [],
    oids: [],
    bets: {},
    messages: [],
    groups: [],
    shortCode: undefined,
    ticketId: undefined,
    ticket_types: [],
    systemGroupBets: [],
    combiGroupBets: [],
    prep_time: undefined,
    date_created: undefined,
  },
  preparedTickets: [],
  shopReady: [false, false, false, false],
  ticketsToApp: { isLoading: false, error: undefined, session: undefined },
};

export const ticketReducer = (
  state = initialState,
  action: ActionType
): VirtualTicketMachine => {
  switch (action.type) {
    case RESET_ALL_TICKETS: {
      return initialState;
    }

    case SEND_TICKETS_TO_APP_RESET: {
      return {
        ...state,
        ticketsToApp: {
          isLoading: false,
          error: undefined,
          session: undefined,
        },
      };
    }
    case SEND_TICKETS_TO_APP_LOADING: {
      return {
        ...state,
        ticketsToApp: { isLoading: true, error: undefined, session: undefined },
      };
    }

    case SEND_TICKETS_TO_APP_ERROR: {
      return {
        ...state,
        ticketsToApp: {
          isLoading: false,
          error: action.meta.error,
          session: undefined,
        },
      };
    }
    case SEND_TICKETS_TO_APP_COMPLETE: {
      return {
        ...state,
        ticketsToApp: {
          isLoading: false,
          error: undefined,
          session: {
            image: action.meta.imageURL,
            description: action.meta.imageDescription,
          },
        },
      };
    }

    case DELETE_PREPARED_VIRTUAL_TICKET: {
      return {
        ...state,
        currentPreparedTicket:
          state.preparedTickets.length > 1
            ? Math.min(action.meta.ticket, state.preparedTickets.length - 2)
            : undefined,
        preparedTickets: state.preparedTickets.filter(
          (ticket, index) => index !== action.meta.ticket
        ),
      };
    }

    case DELETE_VIRTUAL_TICKET_MESSAGE: {
      return {
        ...state,
        ticketServerState: {
          ...state.ticketServerState,
          messages: state.ticketServerState.messages.filter(
            (item, index) => index !== action.meta.index
          ),
        },
      };
    }

    case CHANGE_CURRENT_PREPARED_TICKET: {
      return {
        ...state,
        currentPreparedTicket: action.meta.ticket,
      };
    }

    case SHOW_PREPARED_TICKETS: {
      return {
        ...state,
        isShowingPreparedTickets: true,
      };
    }
    case TICKET_TOGGLE_EXPANDED: {
      const toggledExpanded = !state.isExpanded;
      return {
        ...state,
        isExpanded: toggledExpanded,
      };
    }
    case START_SESSION: {
      const { cookies } = action.meta;
      return {
        ...state,
        cookies: cookies,
      };
    }
    case RESET_TICKET: {
      const { ticket_number } = action.meta;
      return {
        ...state,
        [`ticket${ticket_number}`]: undefined,
      };
    }

    case UPDATE_TICKETS: {
      const { cookies, data, showPrepared } = action.meta;
      const isEmpty = data.oids.length === 0;
      const shopReady = state.shopReady.map((v, i) => {
        return i === data.activeId - 1 ? true && !isEmpty : v;
      });
      if ((data.shortCode ?? '').length === 0) {
        return {
          ...state,
          cookies: cookies,
          ticketServerState: data,
          currentTicket: data.activeId,
          isShowingPreparedTickets: false,
          shopReady,
        };
      } else {
        return {
          ...state,
          cookies: cookies,
          ticketServerState: data,
          currentTicket: data.activeId,
          currentPreparedTicket: 0,
          isShowingPreparedTickets: showPrepared,
          preparedTickets: [data, ...state.preparedTickets],
          shopReady,
        };
      }
    }
    default:
      return state;
  }
};
