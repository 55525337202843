import { ReactComponent as Logo } from '../img/logo_oferta.svg';
import { RegistrationData } from '../store/types';
const PairWithCode: React.FC<{ data?: RegistrationData }> = (props) => {
  const { code, pin, status } = props.data ?? {};
  let text = '!';
  if (status === 0) {
    text = code ?? '------';
  } else if ((status ?? 0) > 0) {
    text = code ?? '';
  }

  return (
    <>
      <div className='bg-black flex flex-col items-center justify-start h-screen'>
        <div className='inline-block h-[150px]'>&nbsp;</div>
        <div className='inline-block'>
          <Logo />
        </div>
        <div className='inline-block h-1/4'>&nbsp;</div>
        <div className='inline-flex flex-col items-center content-center font-bold text-6xl bg-white text-black h-[300px] w-[300px] rounded-full'>
          {(status ?? 0) === 0 && text}
          {(status ?? 0) > 0 && (
            <>
              <span className='inline-block text-2xl'>{text}</span>{' '}
              <span className='inline-block'>{pin}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PairWithCode;
