import React from 'react';

type Props = {
  onClose: React.MouseEventHandler;
  label: string;
};

const BackButton: React.FC<Props> = ({ onClose, label }) => {
  return (
    <button className='nav-button' onClick={onClose}>
      <svg
        id='back-24px'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
      >
        <path id='Path_6' data-name='Path 6' d='M0,0H24V24H0Z' fill='none' />
        <path
          id='Path_7'
          data-name='Path 7'
          d='M20,11H5.3L8.6,7.7a.967.967,0,0,0,0-1.4h0a.967.967,0,0,0-1.4,0L1.5,12l6.2,6.2a.967.967,0,0,0,1.4,0h0a.967.967,0,0,0,0-1.4L5.3,13H20a.945.945,0,0,0,1-1h0A.945.945,0,0,0,20,11Z'
        />
      </svg>
      <span>{label}</span>
    </button>
  );
};
export default BackButton;
