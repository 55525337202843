import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ReactComponent as CloseIcon } from '../img/back.svg';
import { useAppDispatch } from '../store';
import { hideWheel } from '../store/system/actions';

const WheelOfFortune: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef<HTMLIFrameElement>(null);

  return (
    <div className='fixed top-0 left-0 w-screen h-screen z-[99999999] bg-back-primary'>
      <CloseIcon
        className='fixed inline-flex w-20 h-20 z-40 left-4 top-1/2 p-[20px] bg-[#2A2A2A] rounded-full fill-white'
        onClick={() => {
          console.log('Close the wheel');
          dispatch(hideWheel());
        }}
      />
      <iframe
        ref={ref}
        style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
        src='https://roatanorocului.app/'
        width='100%'
        height='100%'
        className='fixed top-0 left-0 w-screen h-screen'
        onClick={() => {
          console.log('onClick');
        }}
        onLoad={() => {
          console.log('wheel on load');
          setIsLoaded(true);
        }}
        onAbort={() => {
          console.log('wheel on abort');
        }}
        onError={(error) => {
          console.log(`wheel on error ${error}`);
        }}
      />
    </div>
  );
};
export default WheelOfFortune;
