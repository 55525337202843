export const RESET_OFFER = 'RESET_OFFER';
export const UPDATE_TODAY = 'UPDATE_TODAY';
export const CHANGE_SELECTED_DATE = 'CHANGE_SELECTED_DATE';
export const CHANGE_SELECTED_CATEGORY = 'CHANGE_SELECTED_CATEGORY';

export const SHOW_TOURNAMENT_DETAILS = 'SHOW_TOURNAMENT_DETAILS';
export const HIDE_TOURNAMENT_DETAILS = 'HIDE_TOURNAMENT_DETAILS';
export const UPDATE_SELECTED_ODDS = 'UPDATE_SELECTED_ODDS';
export const SELECT_PENDING_ODD = 'SELECT_PENDING_ODD';

interface SelectPendingOdd {
  type: typeof SELECT_PENDING_ODD;
  meta: {
    oid: string | undefined;
    bid: string | undefined;
    code: string | undefined;
    pending: boolean;
  };
}
interface ResetOffer {
  type: typeof RESET_OFFER;
  meta: {
    offer: any;
  };
}

interface UpdateToday {
  type: typeof UPDATE_TODAY;
}

interface ChangeSelectedDate {
  type: typeof CHANGE_SELECTED_DATE;
  meta: {
    date: string;
    category: string;
  };
}

interface ChangeSelectedCategory {
  type: typeof CHANGE_SELECTED_CATEGORY;
  meta: {
    category: string;
  };
}

interface ShowTournamentDetails {
  type: typeof SHOW_TOURNAMENT_DETAILS;
  meta: {
    tournament_id: string;
    bet_id?: string;
  };
}

interface HideTournamentDetails {
  type: typeof HIDE_TOURNAMENT_DETAILS;
}

interface UpdateSelectedOdds {
  type: typeof UPDATE_SELECTED_ODDS;
  meta: {
    betIds: string[];
    oids: string[];
  };
}

export type ActionTypes =
  | ResetOffer
  | UpdateToday
  | ChangeSelectedDate
  | ChangeSelectedCategory
  | ShowTournamentDetails
  | HideTournamentDetails
  | UpdateSelectedOdds
  | SelectPendingOdd;

export type Bet = {
  id: string;
  name: string;
  type: string;
  odds: Array<Odd>;
  matchid: string;
  date: string;
  time: string;
  code: number;
  ts: number;
};

export type Category = {
  id: string;
  name: string;
  tournamentsByDate: { [key: string]: string[] };
};

export type Tournament = {
  id: string;
  name: string;
  allTypes: Array<string>;
  typesByDate: { [key: string]: Array<BetTypeInfo> };
};

export type BetTypeInfo = {
  id: string;
  bets: string[];
};

export type BetType = {
  id: string;
  name: string;
  odds: Array<string>;
  info: string;
};

export type Odd = {
  id: string;
  v: string;
  sel: boolean;
  p: boolean;
};

// export type Match
// {
//   public string $id;
//   public string $title;
//   public date $date;
//   public Tournament $t_id;
// }

export type OfferDay = {
  id: string;
  day: string;
  date: string;
  ts: number;
  categories: Array<string>;
  tournaments: Array<string>;
  bets: Array<string>;
};

export type OfferState = {
  categories: { [key: string]: Category };
  tournaments: { [key: string]: Tournament };
  types: { [key: string]: BetType };
  bets: { [key: string]: Bet };
  daysIds: Array<string>;
  days: { [key: string]: OfferDay };
  updated: number;
  v: number;
  selectedDate?: string;
  selectedCategory?: string;
  selectedTournament?: string;
  selectedBet?: string;
  selectedOffer?: SelectedOfferData;
  prevSelectedOdds?: string[];
  prevSelectedBetIds?: string[];
};

export type OfferData = {
  categories: { [key: string]: Category };
  tournaments: { [key: string]: Tournament };
  types: { [key: string]: BetType };
  bets: { [key: string]: Bet };
  days: Array<string>;
  daysMap: { [key: string]: OfferDay };
  updated: number;
  v: number;
};

export type SelectedOfferData = {
  updated: Date;
  tournaments: {
    id: string;
    name: string;
    totalBets: number;
    bets: string[];
    type: BetType;
  }[];
};
