import { useDispatch } from 'react-redux';
import { TicketMessage } from '../../store/ticket/ticket-types';
import { deleteVirtualTicketMessage } from '../../store/ticket/ticket-actions';

interface VerticalTicketFooter {
  shortCode?: string;
  prepareTicket: () => void;
  messages: TicketMessage[];
}
const VerticalTicketFooter: React.FC<VerticalTicketFooter> = (props) => {
  const { shortCode, prepareTicket, messages } = props;
  const dispatch = useDispatch();
  const hasShortCode = `${shortCode || ''}`?.length > 0;
  const handleDeleteMessage = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    // const checked = !(e.currentTarget.dataset.checked === 'true');
    const index = Number(e.currentTarget.dataset.index);
    dispatch(deleteVirtualTicketMessage(index));
  };
  return (
    <div className='virtualTicketFooter'>
      {messages.length > 0 && (
        <div className='ticket-messages'>
          {messages.map((message, index) => (
            <div
              key={`${index}-${message.text}`}
              data-index={index}
              className={`ticket-message ${message.class}`}
              onClick={handleDeleteMessage}
            >
              {message.text}
            </div>
          ))}
        </div>
      )}
      <div
        className={`text-13 active-button flex-grow h-44 justify-center px-24 ${
          hasShortCode ? 'bg-back-darkish' : ''
        } text-bold`}
        onClick={prepareTicket}
      >
        {hasShortCode ? `${shortCode}` : 'Pregătiți un bilet'}
      </div>
    </div>
  );
};

export default VerticalTicketFooter;
