import { createSelector } from 'reselect';
import { RootState } from '..';
import { Tournament } from '../offer/types';

// -- Days

// get the map of days by id
const getDaysMap = (state: RootState) => state.offer.days;

// get an array of all day objects
export const getAllDays = (state: RootState) =>
  state.offer.daysIds.map((index) => state.offer.days[index]);

// get the id of selected day
export const getSelectedDayId = (state: RootState) => state.offer.selectedDate;

// // get a day by id
// export const getDayById = (day: string) => {
//   createSelector(getDaysWithId, (days) => days[day]);
// };

// get the selected day object, if there is one
export const getSelectedDay = createSelector(
  [getSelectedDayId, getDaysMap],
  (day, days) => (day ? days[day!] : undefined)
);

// Categories

// get the map of categories by id
export const getCategoriesMap = (state: RootState) => state.offer.categories;

// get the id of selected category, if any
export const getSelectedCategoryId = (state: RootState) =>
  state.offer.selectedCategory;

// get a category object by id
export const getCategoryById = (id: string) => {
  createSelector([getCategoriesMap], (categories) => categories[id]);
};

// get the selected category object if any
export const getSelectedCategory = createSelector(
  [getSelectedCategoryId, getCategoriesMap],
  (id, categories) => (id ? categories[id!] : undefined)
);

// get an array of categories filtered by id
export const getCategoriesHavingIds = (ids: string[]) => {
  return createSelector([getCategoriesMap], (categories) =>
    ids.map((index) => categories[index])
  );
};

export const getCategoriesInDay = (id: string) => {
  return createSelector([getDaysMap], (days) =>
    getCategoriesHavingIds(days[id].categories)
  );
};

export const getCategoriesInSelectedDay = createSelector(
  [getSelectedDay, getCategoriesMap],
  (day, categories) =>
    day ? day!.categories.map((index) => categories[index]) : []
);

export const getTournaments = (state: RootState) => state.offer.tournaments;

export const getSelectedTournaments = createSelector(
  [getSelectedDay, getSelectedCategory, getTournaments],
  (day, category, tournaments) => {
    if (!day || !category) {
      return Array<Tournament>();
    }
    const d = day!.id;
    if (category) {
      return category!.tournamentsByDate[d].map((t) => tournaments[t]);
    }
    return Array<Tournament>();
  }
);

export const getDetailedTournamentId = (state: RootState) =>
  state.offer.selectedTournament;

// export const getTournamentDetails = createSelector(
//   [getSelectedDay, getSelectedCategory, getDetailedTournamentId],
//   (day, category, tid) => {
//     if (!day || !category || !tid) {
//       return undefined;
//     }
//     let d = day!.id;
//     let ca;

//   }
// );
