import React from 'react';

type EOButtonProps = {
  selected?: boolean;
  centered?: string;
  width?: string | number;
  title?: string;
  subtitle?: string;
  onClick?: (e: any) => void;
  value?: string;
  children?: (string | React.ReactElement | boolean | undefined)[];
  className?: string;
};

const EOButton = (props: EOButtonProps) => {
  const className = `${props.className || ''} fbutton ${
    props.selected ? 'selected' : ''
  } ${props.centered ? 'centered' : ''}`;
  const buttonStyle: React.HTMLAttributes<HTMLButtonElement> &
    React.CSSProperties = {};
  if (props.width) {
    buttonStyle.width = props.width;
  }

  return (
    <button
      className={className}
      style={buttonStyle}
      onClick={props.onClick}
      value={props.value}
    >
      {' '}
      <>
        {props.title}{' '}
        {props.subtitle !== undefined && props.subtitle !== '' && (
          <span> {props.subtitle} </span>
        )}
      </>
    </button>
  );
};

export default EOButton;
