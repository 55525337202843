/** @format */
import { StoreTypes } from '..';
import {
  initApp,
  receiveInitialData,
  updateIsCheckingTicket,
  wsConnect,
  startSession,
  terminateSession,
  stopMovie,
} from '../system/actions';
import {
  changeSelectedDate,
  updateToday,
  resetOffer,
  updateSelectedOdds,
  selectPendingOdd,
} from '../offer/actions';
import {
  sendTicketsToAppError,
  sendTicketsToAppComplete,
  sendTicketsToAppLoading,
  virtualTicketStartSession,
  virtualTicketUpdate,
  resetAllTickets,
} from '../ticket/ticket-actions';
import { OfferStore } from '../types';

import { VirtualTicketState } from '../ticket/ticket-types';
import { wsMessage } from '../system/actions';
import { stat } from 'fs';

const API_SYSTEM = process.env.REACT_APP_API;
const API_CC = process.env.REACT_APP_API_CC;

export const thunkInitApp =
  (): StoreTypes.AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { _id, brand } = state.settings.identity;
    const { wssAPI } = state.settings.config;

    dispatch(wsConnect(wssAPI));

    dispatch(virtualTicketSessionStart());
    const retry = () => {
      return fetchInitialData(brand).then(
        (data) => {
          dispatch(resetOffer(data));
          dispatch(receiveInitialData('01:23'));
          dispatch(updateToday());
          dispatch(setInitialDate());
          dispatch(startSession());
        },
        (error) => {
          console.error('error fetching data ', error);
          setTimeout(() => dispatch(retry), 2000);
        }
      );
    };
    retry();
  };

// Standard variation
function fetchJSON<T>(url: string): Promise<T> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

function fetchInitialData(brand: string) {
  let jsonFile = brand === 'CASA' ? 'cp_offer.json' : 'offer.json';
  return fetchJSON<OfferStore.OfferData>(`${API_SYSTEM}${jsonFile}`);
}

export const changeDate =
  (date: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    let category = `${state.offer.days[date].categories[0]}`;
    if (
      state.offer.days[date].categories.findIndex((c, i) => `${c}` === '3') >= 0
    ) {
      category = '3';
    }
    dispatch(changeSelectedDate(date, category));
  };

export const setInitialDate =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const date = state.offer.daysIds[0];
    let category = state.offer.days[date].categories[0];
    if (
      state.offer.days[date].categories.findIndex((c) => `${c}` === '3') >= 0
    ) {
      category = '3';
    }
    dispatch(changeSelectedDate(date, category));
  };

export const sendTicketToApp =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { token, _id: id } = state.settings!.auth;
    const { retailAppAPI } = state.settings!.config;

    const tickets = state.virtualTicket.preparedTickets.map((ticket) => ({
      code: ticket.shortCode,
      barcode: ticket.ticketId,
      miza: ticket.miza,
      castig: ticket.castig,
      bets: ticket.codes.length,
      type: ticket.ticket_mode_info,
      odds: ticket.totalOddString,
      created: ticket.date_created,
    }));

    dispatch(sendTicketsToAppLoading());
    const params = { id, token, tickets };
    console.log(params);
    fetch(`${retailAppAPI}/client/send_tickets_to_app`, {
      method: 'post',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((result) => {
        const { imageURL, imageDescription } = result;
        if (imageURL && imageDescription) {
          dispatch(sendTicketsToAppComplete(imageURL, imageDescription));
        }
      })
      .catch((err) => {
        dispatch(sendTicketsToAppError(`${err}`));
      });
  };

export const toggleCheckTicket =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { isCheckingTicket } = state.system;
    dispatch(updateIsCheckingTicket(!isCheckingTicket));
  };

export const virtualTicketSessionStart =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const { brand } = state.settings.identity;
    const { ticketsAPI } = state.settings!.config;
    fetch(`${ticketsAPI}/ticket/start-session/`, {
      method: 'POST',
      body: JSON.stringify({ brand: brand }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log('got json: ', json);
        const { cookies } = json;
        dispatch(virtualTicketStartSession(cookies));
      })
      .catch((error) => {
        console.error('error starting session: ', error);
      });
  };

export const virtualTicketAddBet =
  (
    code: string,
    oid: string,
    oddv: string,
    b_id: string
  ): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = getState();

    if (getState().virtualTicket.ticketServerState.oids.indexOf(oid) >= 0) {
      dispatch(virtualTicketRemoveBet(code, oid, b_id));
      return;
    }
    document.dispatchEvent(new Event('click'));
    const state = getState();
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    // dispatch(selectPendingOdd(b_id, code, oid, true));

    fetch(`${ticketsAPI}/ticket/bet/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        code,
        oid,
        oddv,
        cookies,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log('got json: ', json);
        // dispatch(selectPendingOdd(b_id, code, oid, false));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });
        dispatch(updateSelectedOdds(betIds, oids));
        dispatch(virtualTicketUpdate(json));
      })
      .catch((error) => {
        console.error('error starting session: ', error);
        dispatch(selectPendingOdd(b_id, code, oid, false));
      });
  };

export const virtualTicketRemoveBet =
  (code: string, oid: string, b_id: string | undefined): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const {
      virtualTicket: { cookies, currentTicket },
    } = getState();
    const state = getState();
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `virtualTicketRemoveBet code: [${code}] oid: [${oid}] `;
    document.dispatchEvent(new Event('click'));
    if (undefined !== b_id) {
      // dispatch(selectPendingOdd(b_id, code, oid, true));
    }

    fetch(`${ticketsAPI}/ticket/bet/remove`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        code,
        oid,

        cookies,
        ticket: currentTicket,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, 'got json: ', json);

        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        if (undefined !== b_id) {
          // dispatch(selectPendingOdd(b_id, code, oid, false));
        }
        dispatch(updateSelectedOdds(betIds, oids));
        dispatch(virtualTicketUpdate(json));
      })
      .catch((error) => {
        console.error(OP, error);
        if (undefined !== b_id) {
          dispatch(selectPendingOdd(b_id, code, oid, false));
        }
      });
  };

export const virtualTicketChangeMiza =
  (miza: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;

    const OP = `virtualTicketChangeMixa [${miza}] `;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/miza`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ brand, miza, cookies, ticket: currentTicket }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, ' got json: ', json);
        dispatch(virtualTicketUpdate(json));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const virtualTicketPrepare =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { _id: uuid } = state.settings!.identity;
    const { brand } = state.settings.identity;
    const OP = `virtualTicketPrepare`;
    document.dispatchEvent(new Event('click'));
    fetch(`${ticketsAPI}/ticket/prepare`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ brand, cookies, ticket: currentTicket }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.debug(OP, 'got json: ', json);
        const now = new Date();
        const time =
          ('0' + now.getHours()).slice(-2) +
          ':' +
          ('0' + now.getMinutes()).slice(-2);
        if (json['ticket']) {
          json['ticket']['prep_time'] = time;
          json['ticket']['date_created'] = now;
        }

        dispatch(virtualTicketUpdate(json, true));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;

        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        dispatch(
          wsMessage({
            appId: uuid,
            isTouchscreen: true,
            rx_action: {
              type: 'VT_PREPARED',
              code: data.shortCode,
              ticketId: data.ticketId,
              ticketMode: data.ticket_mode,
              ticketMiza: data.miza,
              ticketBets: oids.length,
              ticketCastig: data.castig,
              ticketActiveId: data.activeId,
            },
            dt: 0,
          })
        );

        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeCurrentTicket =
  (ticket: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `changeCurrentTicket [${ticket}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/change-current`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        ticket,
        shopReady: shopReady[ticket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        dispatch(virtualTicketUpdate(json));
        const data = json['ticket'] as VirtualTicketState;
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeTicketType =
  (type: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `changeTicketType [${type}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/type`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        type,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeBetGroup =
  (code: string, oid: string, group: string): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `changeBetGroup code: [${code}] oid: [${oid}] group: [${group}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/changebetsgroup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        code,
        oid,
        group,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeFixedBet =
  (code: string, oid: string, fixed: boolean): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `changeFixedBet code: [${code}] oid: [${oid}] group: [${fixed}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/changefixedbet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        code,
        oid,
        fixed,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeSystemBetGroupChecked =
  (index: string, checked: boolean): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `changeFixedBet index: [${index}] checked: [${checked}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/markbetgroup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        index,
        checked,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeSystemBetGroupValue =
  (index: string, value: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `changeSystemBetGroupMiza index: [${index}] value: [${value}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/system-group-value`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        index,
        value,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const changeCombiBetGroupValue =
  (group: boolean, index: string, value: number): StoreTypes.AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = `changeCombiBetGroupValue index: [${index}] value: [${value}]`;
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/combi-group-value`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        index,
        value,
        group,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const virtualTicketResetMiza =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket, shopReady },
    } = state;
    const { ticketsAPI } = state.settings!.config;
    const { brand } = state.settings.identity;
    const OP = 'virtualTicketResetMiza';
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/reset-miza`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        ticket: currentTicket,
        shopReady: shopReady[currentTicket - 1],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

export const deleteCurrentVirtualTicket =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const state = getState();
    const {
      virtualTicket: { cookies, currentTicket },
    } = state;
    const { ticketsAPI } = state.settings.config;
    const { brand } = state.settings.identity;
    const OP = 'deleteCurrentVirtualTicket';
    document.dispatchEvent(new Event('click'));

    fetch(`${ticketsAPI}/ticket/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        brand,
        cookies,
        ticket: currentTicket,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(OP, ' json: ', json);
        const data = json['ticket'] as VirtualTicketState;
        if (!data) {
          console.error('did not receive ticket data');
          return;
        }
        dispatch(virtualTicketUpdate(json));
        const { oids, bets } = data;
        const betIds = oids.map((oid) => {
          return bets[oid].matchId;
        });

        console.debug(OP, ' updating selected odds: ', betIds, oids);
        dispatch(updateSelectedOdds(betIds, oids));
      })
      .catch((error) => {
        console.error(OP, error);
      });
  };

// OFFER UPDATE

export const updateOffer = (): StoreTypes.AppThunk => (dispatch, getState) => {
  console.debug('Thunk updateOffer');
  dispatch(terminateSession());
  dispatch(setInitialDate());
  const state = getState();
  const { brand } = state.settings.identity;
  const retry = () => {
    console.debug('Thunk updateOffer fetching..');
    // performance.mark('downloadOffer');
    return fetchInitialData(brand).then(
      (data) => {
        // console.log(
        //   performance.measure('thunk download offer', 'downloadOffer')
        // );
        // performance.mark('resetOffer');

        dispatch(resetOffer(data));
        // console.log(performance.measure('thunk reset offer', 'resetOffer'));
        // performance.mark('updateToday');
        dispatch(updateToday());
        // console.log(performance.measure('thunk updateToday', 'updateToday'));
        dispatch(setInitialDate());
        console.debug('Thunk updateOffer complete');
      },
      (error) => {
        console.error('error fetching data ', error);
        setTimeout(() => dispatch(retry), 2000);
      }
    );
  };
  setTimeout(() => retry(), 1200);
};

export const startNewSession =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    const {
      system: { isCheckingTicket, isPlayingMovie },
      virtualTicket: { cookies, currentTicket },
    } = getState();

    dispatch(resetAllTickets());
    dispatch(virtualTicketSessionStart());
    console.debug('Starting a new session, movie will stop playing');
    dispatch(startSession());
    // dispatch(setInitialDate());
    console.debug('Thunk startNewSession started');
  };

// clear selected bets

export const showScanTicketThunk =
  (): StoreTypes.AppThunk => (dispatch, getState) => {
    // const {
    //   system: { sessionId },
    // } = getState();

    console.log(`showScanTicketThunk`);

    // if (sessionId === undefined) {
    //   dispatch(startSession());
    // }
    dispatch(updateIsCheckingTicket(false));
    dispatch(updateIsCheckingTicket(true));
  };
