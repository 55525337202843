import emptyTicketPlaceholder from '../../img/empty_ticket_placeholder.png';
import TicketMoviePlayer from '../TicketMoviePlayer';

const TICKET_VIDEO_URL = `${process.env.PUBLIC_URL}/media/CP_ticket_546x700_no_audio20.mp4`;

const EmptyVirtualTicketBody: React.FC = () => {
  return (
    <div
      className='flex'
      style={{
        width: '100%',
        margin: 0,
        padding: 0,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* <div className='inline-bflex flex-col items-center justify-center content-center h-full '> */}
      {/* <img
          className='inline-block mb-12'
          src={emptyTicketPlaceholder}
          alt='Ticket is empty'
        />
        <div className='text-lg semibold'>Biletul este gol</div> */}
      <TicketMoviePlayer width={546} height={700} url={TICKET_VIDEO_URL} />
      {/* </div> */}
    </div>
  );
};
export default EmptyVirtualTicketBody;
