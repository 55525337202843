import React, { SyntheticEvent, useCallback } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../store';
import { OfferDay } from '../store/offer/types';
import EOButton from './EOButton';

import { changeDate as changeSelectedDate } from '../store/thunks';

type AppProps = {
  children?: React.ReactChildren;
  days: Array<OfferDay>;
  dispatch: any;
  selectedDate?: string;
  isTouchscreen: boolean;
};
type TargetWithValue = {
  value: string;
};

const Calendar: React.FC<AppProps> = (props: AppProps) => {
  const { days, selectedDate, dispatch } = props;

  const changeDate = useCallback(
    (e: SyntheticEvent<TargetWithValue>) => {
      dispatch(changeSelectedDate(e.currentTarget.value));
    },
    [dispatch]
  );

  return (
    <div id='calendar'>
      <ul className='hours'>
        {days.slice(0, 4).map((day) => (
          <li key={day.id} className='date'>
            <EOButton
              selected={day.id === selectedDate}
              title={day.day}
              subtitle={day.date === '' ? undefined : day.date}
              centered={day.day !== 'Astăzi' ? 'center' : undefined}
              value={day.id}
              onClick={changeDate}
              className={`date ${day.day === 'Astăzi' ? 'today' : ''} ${
                day.day.substr(0, 1) === '+' ? 'hour' : ''
              }`}
            />
          </li>
        ))}
      </ul>
      <ul className='dates'>
        {days.slice(4).map((day) => (
          <li key={day.id} className='date'>
            <EOButton
              selected={day.id === selectedDate}
              title={day.day}
              subtitle={day.date === '' ? undefined : day.date}
              centered={day.day !== 'Astăzi' ? 'center' : undefined}
              value={day.id}
              onClick={changeDate}
              className={`date ${day.day === 'Astăzi' ? 'today' : ''} ${
                day.day.substr(0, 1) === '+' ? 'hour' : ''
              }`}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { offer } = state;
  return {
    days: offer.daysIds.map((d) => offer.days[d]),
    selectedDate: offer.selectedDate,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchActions)(Calendar);
