import axios from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import LoadingScreen from './pages/LoadingScreen';
import PairWithCode from './pages/PairWithCode';
import { RegistrationData } from './store/types';
import { Settings } from './store/settings/types';
import { useDispatch } from 'react-redux';
import { setSetting } from './store/settings/actions';

import { fetchSettings } from './pixel_api';
enum SettingsState {
  Unknown = 0,
  Restoring,
  Registering,
  Complete,
}
const fakeRegistration = process.env.REACT_APP_REGISTRATION as
  | string
  | undefined;
const fetchRegistration = async () => {
  if (fakeRegistration) {
    return JSON.parse(fakeRegistration) as RegistrationData;
  }
  let result = await axios.get('http://localhost:8080/auth/registration', {
    timeout: 4000,
  });
  const jsResult = result.data as RegistrationData & { err: string };
  return jsResult;
};

const AppBootloader: React.FC = () => {
  const [settingsState, setSettingsState] = useState(SettingsState.Registering);
  const isRegistering = settingsState === SettingsState.Registering;
  const needSettings =
    settingsState === SettingsState.Unknown ||
    settingsState === SettingsState.Restoring;
  const isRestoring = settingsState === SettingsState.Restoring;
  const { data: registrationData } = useQuery(
    ['/registration'],
    fetchRegistration,
    {
      enabled: isRegistering,
      retry: true,
      retryDelay: 2 * 1000,
      refetchInterval: 5 * 1000,
    }
  );

  const code = registrationData?.code;
  const { data: settings } = useQuery(
    ['/settings'],
    () => fetchSettings(isRestoring, code),
    {
      retry: true,
      enabled: needSettings,
      retryDelay: 2 * 1000,
      refetchInterval: 5 * 1000,
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.debug(`code changed ${code}`);
  }, [code]);

  useEffect(() => {
    console.debug(
      'useEffect [settings, registrationData]=>',
      settings,
      registrationData
    );
    if (
      ((settings as Settings)?.identity?._id ?? '').length >= 6 &&
      settings !== false
    ) {
      console.debug('State complete');
      dispatch(setSetting(settings!));

      setSettingsState(SettingsState.Complete);
      return undefined;
    }

    // for sure device is not registered yet
    if (settings === false && settingsState === SettingsState.Unknown) {
      console.debug('State registering');
      setSettingsState(SettingsState.Registering);
      return undefined;
    }

    if (registrationData !== undefined) {
      // got registration info
      if (registrationData?.status === 3) {
        console.debug('State restoring');
        setSettingsState(SettingsState.Restoring); // status complete, restoring or starting new session
      } else {
        if (settingsState === SettingsState.Unknown) {
          console.debug('State registering');
          setSettingsState(SettingsState.Registering);
        }
      }
      console.log('something else');
      return undefined;
    }
  }, [settings, registrationData]);

  return (
    <>
      {(() => {
        switch (settingsState) {
          case SettingsState.Unknown:
            return <LoadingScreen progress={10} />;
          case SettingsState.Restoring:
            return <LoadingScreen progress={40} />;
          case SettingsState.Complete:
            return <LoadingScreen progress={100} />;
          case SettingsState.Registering:
            return <PairWithCode data={registrationData} />;
        }
      })()}
    </>
  );
};

export default AppBootloader;
