export const RESET_TICKET = 'RESET_TICKET';
export const START_SESSION = 'VIRTUAL_TICKET_START_SESSION';
export const UPDATE_TICKETS = 'VIRTUAL_TICKET_UPDATE';
export const TICKET_TOGGLE_EXPANDED = 'TICKET_TOGGLE_EXPANDED';
export const SHOW_PREPARED_TICKETS = 'SHOW_PREPARED_TICKETS';
export const CHANGE_CURRENT_TICKET = 'CHANGE_CURRENT_TICKET';
export const CHANGE_CURRENT_PREPARED_TICKET = 'CHANGE_CURRENT_PREPARED_TICKET';
export const DELETE_VIRTUAL_TICKET_MESSAGE = 'DELETE_VIRTUAL_TICKET_MESSAGE';
export const DELETE_PREPARED_VIRTUAL_TICKET = 'DELETE_PREPARED_VIRTUAL_TICKET';
export const SEND_TICKETS_TO_APP_LOADING = 'SEND_TICKETS_TO_APP_LOADING';
export const SEND_TICKETS_TO_APP_COMPLETE = 'SEND_TICKETS_TO_APP_COMPLETE';
export const SEND_TICKETS_TO_APP_ERROR = 'SEND_TICKETS_TO_APP_ERROR';
export const SEND_TICKETS_TO_APP_RESET = 'SEND_TICKETS_TO_APP_RESET';
export const RESET_ALL_TICKETS = 'RESET_ALL_TICKETS';
interface SendTicketsToAppLoading {
  type: typeof SEND_TICKETS_TO_APP_LOADING;
}
interface SendTicketsToAppError {
  type: typeof SEND_TICKETS_TO_APP_ERROR;
  meta: {
    error: string;
  };
}

interface SendTicketsToAppComplete {
  type: typeof SEND_TICKETS_TO_APP_COMPLETE;
  meta: {
    imageURL: string;
    imageDescription: string;
  };
}

interface SendTicketsToAppReset {
  type: typeof SEND_TICKETS_TO_APP_RESET;
}

interface DeletePreparedVirtualTicket {
  type: typeof DELETE_PREPARED_VIRTUAL_TICKET;
  meta: {
    ticket: number;
  };
}

interface DeleteVirtualTicketMessage {
  type: typeof DELETE_VIRTUAL_TICKET_MESSAGE;
  meta: {
    index: number;
  };
}
interface ChangeCurrentPreparedTicket {
  type: typeof CHANGE_CURRENT_PREPARED_TICKET;
  meta: {
    ticket?: number;
  };
}

interface ChangeCurrentTicket {
  type: typeof CHANGE_CURRENT_TICKET;
  meta: {
    ticket: number;
  };
}

interface ShowPreparedTickets {
  type: typeof SHOW_PREPARED_TICKETS;
}

interface ToggleTicketExpanded {
  type: typeof TICKET_TOGGLE_EXPANDED;
}

interface ResetTicket {
  type: typeof RESET_TICKET;
  meta: {
    ticket_number: number;
  };
}

interface VirtualTicketStartSessionAction {
  type: typeof START_SESSION;
  meta: {
    cookies: string;
  };
}

interface VirtualTicketUpdateAction {
  type: typeof UPDATE_TICKETS;
  meta: {
    cookies: string;
    data: VirtualTicketState;
    showPrepared: boolean;
  };
}

interface ResetAllTickets {
  type: typeof RESET_ALL_TICKETS;
}

export type ActionType =
  | ResetTicket
  | VirtualTicketStartSessionAction
  | VirtualTicketUpdateAction
  | ToggleTicketExpanded
  | ShowPreparedTickets
  | ChangeCurrentTicket
  | ChangeCurrentPreparedTicket
  | DeleteVirtualTicketMessage
  | DeletePreparedVirtualTicket
  | SendTicketsToAppLoading
  | SendTicketsToAppError
  | SendTicketsToAppComplete
  | SendTicketsToAppReset
  | ResetAllTickets;

export type VirtualTicketMachine = {
  cookies?: string;
  isVisible: boolean;
  isExpanded: boolean;
  currentTicket: number;
  currentPreparedTicket: number | undefined;
  ticketServerState: VirtualTicketState;
  codes: string[];
  preparedTickets: VirtualTicketState[];
  isShowingPreparedTickets: boolean;
  shopReady: boolean[];
  ticketsToApp: {
    isLoading: boolean;
    error?: string;
    session?: { image: string; description: string };
  };
};

export interface VirtualTicketState {
  activeId: number;
  data_ids1: number[];
  data_ids2: number[];
  data_ids3: number[];
  data_ids4: number[];
  totalOdd: number;
  totalOddString: string;
  miza: number;
  castig: number;
  legal: string;
  ticket_mode: string;
  ticket_mode_info: string;
  codes: string[];
  oids: string[];
  bets: { [key: string]: TicketBet };
  messages: TicketMessage[];
  groups: TicketGroup[];
  shortCode: string | undefined;
  prep_time: string | undefined;
  date_created: Date | undefined;
  ticketId: string | undefined;
  ticket_types: TicketType[];
  systemGroupBets: SystemGroupBet[];
  combiGroupBets: CombiGroupBet[];
}
export interface CombiGroupBet {
  index: number;
  info: string;
  group: string;
  combination: string;
}
export interface SystemGroupBet {
  checked: boolean;
  value: string;
  index: number;
  info: string;
}

export interface TicketType {
  id: string;
  label: string;
  selected: boolean;
}

export interface TicketMessage {
  text: string;
  class: string;
}

export interface TicketGroup {
  name: string;
  selected: boolean;
  odd: string | undefined;
  bets: string[];
}

export interface OddInfo {
  id: string;
  info: string;
  value: string;
  name: string;
  selected: boolean;
}

export interface TicketBet {
  code: string;
  title: string;
  name: string;
  id: string;
  index: string;
  matchId: string;
  sport: string;
  sportId: string;
  competition: string;
  competitionId: string;
  description: string;
  odd: { name: string; value: string };
  otherOdds: OddInfo[];
  groups: TicketGroup[];
  class: string;
  fixed: boolean;
  icon: string;
}
