import { ReactComponent as ArrowUp } from '../../img/arrow-up-24px.svg';

interface VerticalScrollButtonsProps {
  onScroll: (up: boolean) => void;
  canScrollUp: boolean;
  canScrollDown: boolean;
  className: string;
}

const VerticalScrollButtons: React.FC<VerticalScrollButtonsProps> = (props) => {
  const { onScroll, canScrollUp, canScrollDown, className } = props;
  return (
    <div className={'flex flex-col ' + className}>
      <div
        className={
          'scroll-button mb-2 transition duration-300 ' + (canScrollUp === false ? 'disabled' : '')
        }
        onClick={() => onScroll(true)}
      >
        <ArrowUp className='fill-current' />
      </div>
      <div
        className={
          'scroll-button mb-2 transition duration-300 ' + (canScrollDown === false ? 'disabled' : '')
        }
        onClick={() => onScroll(false)}
      >
        <ArrowUp className='rotate-180 fill-current' />
      </div>
    </div>
  );
};

export default VerticalScrollButtons;
