import {
  ActionTypes,
  UPDATE_CONFIG,
  AppConfiguration,
  SettingsState,
  SET_SETTINGS,
} from './types';
export function updateConfig(config: AppConfiguration): ActionTypes {
  return {
    type: UPDATE_CONFIG,
    meta: { config },
  };
}

export function setSetting(settings: SettingsState): ActionTypes {
  return {
    type: SET_SETTINGS,
    meta: { settings },
  };
}
