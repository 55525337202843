import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { RootState } from '../../store';
import { ReactComponent as PaperCutDecoration } from '../../img/paper-cut-22px.svg';

import { wsMessage } from '../../store/system/actions';

import { ReactComponent as WhiteCheckedIcon } from '../../img/white-checkmark-24px.svg';
import { ReactComponent as WhiteXIcon } from '../../img/white-close-24px.svg';

import {
  DogsBet,
  LiveBet,
  NormalizedTicketResult,
  TicketStatus,
  VirtualGameProduct,
} from '../../data/VirtualGamesTypes';

const fetcher = (
  url: string,
  brand: string,
  ticket_code: string,
  sessionId: string,
  appId: string
) =>
  fetch(
    `${url}?${new URLSearchParams({ ticket_code, brand, sessionId, appId })}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },

      // param:[],
    }
  )
    .then((res) => res.json())
    .then((json) => {
      console.log('Fetch ticket results', ' json: ', json);
      let ticket: NormalizedTicketResult | undefined;
      if (json['result'] === '0') {
        throw Error('Ticket not found');
      }
      if (json['result'] !== '1') {
        throw Error(`Unexpected result: ${json['result']}`);
      }
      ticket = json['ticket'] as NormalizedTicketResult;
      return ticket;
    });

const StatusInfo = new Map([
  [TicketStatus.Winning, { label: 'Câștigător', color: '#19FF84' }],
  [
    TicketStatus.Losing,
    {
      label: 'Necâștigător',
      color: '#FFA89F',
    },
  ],
  [
    TicketStatus.Open,
    {
      label: 'În desfășurare',
      color: '#ffd639',
    },
  ],
]);

const LuckySixNumberTypes = [0, 1, 2, 3, 4, 10];

const VirtualGamesTicketResults: React.FC<{
  ticketId: string;
  onError: () => void;
}> = (props) => {
  const { ticketId, onError } = props;

  const appId = useSelector<RootState>((state) => state.settings!.identity._id);
  const ticketsAPI = useSelector<RootState>(
    (state) => state.settings!.config.ticketsAPI
  );

  const brand = useSelector<RootState>(
    (state) => state.settings.identity.brand
  ) as string;

  const dispatch = useDispatch();
  const { data: ticket, error } = useSWR<NormalizedTicketResult, any>(
    [`${ticketsAPI}/virtual-games/test`, brand.toLowerCase(), ticketId, appId],
    fetcher,
    {
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        console.log('error retry! ', error);
        if (`${error}` === 'Error: Ticket not found') {
          console.log('should stop retrying.');
          return;
        }
        if (retryCount >= 10) return;
        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
      },
    }
  );

  useEffect(() => {
    if (ticket || error) {
      dispatch(
        wsMessage({
          appId,

          isTouchscreen: true,
          rx_action: {
            type: 'CHECK_NSOFT_TICKET',
            code: `${ticketId}`,
            error: error,
          },
          dt: 0,
        })
      );
    }
  }, [dispatch, appId, ticketId, error, ticket]);

  if (error)
    return (
      <div className='w-screen h-screen text-black text-20 semibold flex flex-col items-center content-center screen-padding box-border'>
        <div>Biletul nu a fost găsit. Te rugăm să încerci din nou.</div>
        <div className='text-center mt-32'>
          <div
            className='inline-block h-44 lh-44 w-100 text-center text-13 text-black bold bg-yellow rounded-4'
            onClick={onError}
            style={{ width: '160px' }}
          >
            OK
          </div>
        </div>
      </div>
    );
  if (!ticket)
    return (
      <div className='w-screen h-screen text-black  text-20 semibold flex flex-row items-center content-center screen-padding box-border'>
        <div>Se caută biletul..</div>
      </div>
    );

  const modeName: string = ticket.game;
  const statusInfo = StatusInfo.get(ticket.status)!;

  // render data
  return (
    <div className='w-screen h-screen text-black flex flex-row items-center content-center screen-padding box-border'>
      <div className='flex flex-col h-full ' style={{ width: 420 }}>
        <h1 className='text-24 bold'>Verifică bilet</h1>
        <h2 className='text-12 semibold'>
          Această verificare nu reprezintă omologarea oficială a Biletului!
        </h2>
        <div className='' style={{ height: 14 }}>
          <PaperCutDecoration />
        </div>
        <div className='bg-white text-gray-100 text-14 p-32 flex-grow'>
          <div className='flex space-between lh-32 items-center mb-32'>
            <div className='inline-block text-24 bold'>{ticket.id}</div>
            <div
              className='inline-block text-14 semibold px-12 rounded-17'
              style={{ backgroundColor: statusInfo.color }}
            >
              {statusInfo.label}
            </div>
          </div>
          <div className='text-right py-16 border-solid border-y-2 border-black'>
            <b>{modeName}</b>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Miza</b>
            <span>{Number(ticket.bet).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>C.C.</b>
            <span>{Number(ticket.payInTax).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Total</b>
            <span>{Number(ticket.payIn).toFixed(2)}</span>
          </div>

          <div className='h-48 lh-48 flex space-between border-solid border-y-2 border-black'>
            <b>Câștig maxim</b>
            <span>
              {ticket.maxPossibleWin === undefined
                ? '-'
                : Number(ticket.maxPossibleWin).toFixed(2)}
            </span>
          </div>
          {ticket.status === TicketStatus.Winning && (
            <div className='h-48 lh-48 flex space-between border-solid border-y-2 border-black'>
              <b>Câștig</b>
              <span>{Number(ticket.winnings).toFixed(2)}</span>
            </div>
          )}
          <div className='inline-block flex-grow flex-col'>&nbsp;</div>
        </div>
        <div className='' style={{ height: 14, transform: 'rotate(180deg)' }}>
          <PaperCutDecoration />
        </div>
      </div>
      <div
        className='overflow-y-scroll ml-24 items-start h-full p-t-24 box-border'
        style={{ paddingTop: 64 }}
      >
        {ticket.game === VirtualGameProduct.Live && (
          <LiveBets
            title={ticket.liveBetTitle ?? ''}
            bets={ticket.liveBets ?? []}
            status={ticket.status}
          />
        )}

        {ticket.game === VirtualGameProduct.LuckySix &&
          (ticket.luckySixBets ?? []).map((bet, index) => (
            <article className='mb-10 min-w-[500px]' key={index}>
              <header className='bg-[#3E3E3E] text-white text-sm font-semibold py-2 text-center mb-2'>
                Runda {bet.round}
              </header>
              {LuckySixNumberTypes.indexOf(bet.type) >= 0 && (
                <NumbersVirtualBetRow
                  type={bet.specialTypeValue ?? bet.typeValue}
                  numbers={bet.value}
                  results={bet.results}
                  miza={'0'}
                  winning={`${(bet.status === TicketStatus.Winning
                    ? bet.winnings
                    : bet.possibleWinnings
                  ).toFixed(2)} Lei`}
                  status={bet.status}
                />
              )}

              {LuckySixNumberTypes.indexOf(bet.type) < 0 && (
                <SpecialVirtualBetRow
                  type={bet.specialTypeValue ?? bet.typeValue}
                  betName={bet.specialValue ?? ''}
                  miza={'0'}
                  winning={`${(bet.status === TicketStatus.Winning
                    ? bet.winnings
                    : bet.possibleWinnings
                  ).toFixed(2)} Lei`}
                  status={bet.status}
                />
              )}
            </article>
          ))}

        {ticket.game === VirtualGameProduct.Dogs &&
          (ticket.dogsBets ?? []).map((bet, index) => (
            <article className='mb-10 min-w-[500px]' key={index}>
              <header className='bg-[#3E3E3E] text-white text-sm font-semibold py-2 text-center mb-2'>
                Runda {bet.round}
              </header>

              <DogsBetsRow bet={bet} />
            </article>
          ))}
      </div>
    </div>
  );
};
export default VirtualGamesTicketResults;

const NumbersVirtualBetRow: React.FC<{
  type: string;

  numbers: string[];
  results: string[];
  miza: string;
  winning: string;
  status: TicketStatus;
}> = ({ type, numbers, results, miza, winning, status }) => {
  return (
    <article className='mb-4 min-w-[500px]'>
      <header className=' flex text-black text-sm font-regular py-2'>
        <span className='inline-block flex-1'>{type}</span>
        <span className='inline-block'>
          {status === TicketStatus.Winning ? 'Castig' : 'Castig ev.'}
        </span>
      </header>
      <div className='flex items-center'>
        <span className='inline-flex items-center bg-back-secondary text-black font-semibold text-sm flex-1 mr-1 rounded px-8 py-1'>
          {numbers.map((item) => (
            <span
              key={item}
              className={`inline-flex items-center justify-center w-8 h-8 mr-2 font-semibold  rounded-full ${
                results.indexOf(item) >= 0 ? 'bg-winning' : 'bg-white'
              }  text-black`}
            >
              {item}
            </span>
          ))}
        </span>

        <span
          className={`inline-block z-10 text-black font-semibold text-right text-sm w-32 rounded px-8 py-2 ${
            status === TicketStatus.Losing ? 'bg-altLosing' : ''
          } ${status === TicketStatus.Winning ? 'bg-altWinning' : ''} ${
            status === TicketStatus.Open ? 'bg-white' : ''
          }`}
        >
          {winning}
        </span>
        {status === TicketStatus.Losing && (
          <span className='h-9  w-10 z-0 inline-flex items-center justify-center bg-losing rounded-r -ml-1'>
            <WhiteXIcon />
          </span>
        )}
        {status === TicketStatus.Winning && (
          <span className='h-9 w-10 z-0 inline-flex items-center justify-center bg-winning  rounded-r -ml-1'>
            <WhiteCheckedIcon />
          </span>
        )}
      </div>
    </article>
  );
};

const SpecialVirtualBetRow: React.FC<{
  type: string;
  betName: string;
  miza: string;
  winning: string;
  status: TicketStatus;
}> = ({ type, betName, miza, winning, status }) => {
  return (
    <article className='mb-4 min-w-[500px]'>
      <header className=' flex text-black text-sm font-regular py-2'>
        <span className='inline-block flex-1'>{type}</span>
        <span className='inline-block'>
          {status === TicketStatus.Winning ? 'Castig' : 'Castig ev.'}
        </span>
      </header>
      <div className='flex items-center'>
        <span className='inline-block bg-white text-black font-semibold text-sm flex-1 mr-1 rounded px-8 py-2'>
          {betName}
        </span>

        <span
          className={`inline-block z-10 text-black font-semibold text-right text-sm w-32 rounded px-8 py-2 ${
            status === TicketStatus.Losing ? 'bg-altLosing' : ''
          } ${status === TicketStatus.Winning ? 'bg-altWinning' : ''} ${
            status === TicketStatus.Open ? 'bg-white' : ''
          }`}
        >
          {winning}
        </span>
        {status === TicketStatus.Losing && (
          <span className='h-9  w-10 z-0 inline-flex items-center justify-center bg-losing rounded-r -ml-1'>
            <WhiteXIcon />
          </span>
        )}
        {status === TicketStatus.Winning && (
          <span className='h-9 w-10 z-0 inline-flex items-center justify-center bg-winning  rounded-r -ml-1'>
            <WhiteCheckedIcon />
          </span>
        )}
      </div>
    </article>
  );
};

const DogsBetsRow: React.FC<{ bet: DogsBet }> = ({ bet }) => {
  return (
    <article className='mb-4 min-w-[500px]'>
      <header className=' flex text-black text-sm font-regular py-2'>
        <span className='inline-block flex-1'>{bet.title}</span>
        <span className='inline-block'>
          {bet.status === TicketStatus.Winning ? 'Castig' : 'Castig ev.'}
        </span>
      </header>
      {bet.combinations.map((b, i) => (
        <div className='flex items-center mb-1' key={i}>
          <span className='inline-block bg-white text-black font-semibold text-sm flex-1 mr-1 rounded px-8 py-2'>
            {b.value}
          </span>

          <span
            className={`inline-block z-10 text-black  font-semibold text-right text-sm w-32 rounded px-8 py-2 ${
              b.status === TicketStatus.Losing ? 'bg-altLosing' : ''
            } ${b.status === TicketStatus.Winning ? 'bg-altWinning' : ''} ${
              b.status === TicketStatus.Open ? 'bg-white' : ''
            }`}
          >
            {b.possibleWin}
          </span>
          {b.status === TicketStatus.Losing && (
            <span className='h-9 w-10 z-0 inline-flex items-center justify-center bg-losing rounded-r -ml-1'>
              <WhiteXIcon />
            </span>
          )}
          {b.status === TicketStatus.Winning && (
            <span className='h-9 w-10 z-0 inline-flex items-center justify-center bg-winning  rounded-r -ml-1'>
              <WhiteCheckedIcon />
            </span>
          )}
        </div>
      ))}
    </article>
  );
};

const LiveBets: React.FC<{
  bets: LiveBet[];
  title: string;
  status: TicketStatus;
}> = ({ bets, title, status }) => {
  return (
    <article className='mb-4 min-w-[600px] '>
      <header className='rounded-t flex bg-back-secondary  text-black text-sm font-regular py-2 mb-2'>
        <span className='inline-block flex-1 px-8 box-border'>{title}</span>
        <span className='inline-block w-16 text-center ml-1'>Tip</span>
        <span className='inline-block w-32 text-center ml-1'>Cota</span>
        <span className='inline-block w-10 text-center'></span>
      </header>
      {bets.map((b, i) => (
        <div className='flex items-center mb-1 flex-row' key={i}>
          <div className='inline-flex flex-col bg-white text-black font-semibold text-sm flex-1 mr-1 rounded px-8 py-0'>
            <span className='inline-block w-full text-sm font-semibold'>
              {b.matchName}
            </span>
            <span className='inline-block w-full text-xs'>{b.betName}</span>
          </div>
          <span
            className={`inline-block z-10 text-black  font-semibold text-center text-sm w-16 rounded px-8 py-2 mr-1 ${
              b.status === TicketStatus.Losing ? 'bg-altLosing' : ''
            } ${b.status === TicketStatus.Winning ? 'bg-altWinning' : ''} ${
              b.status === TicketStatus.Open ? 'bg-white' : ''
            }`}
          >
            {b.betOutcome}
          </span>
          <span
            className={`inline-block z-10 text-black font-semibold text-center text-sm w-32 rounded px-8 py-2 ${
              b.status === TicketStatus.Losing ? 'bg-altLosing' : ''
            } ${b.status === TicketStatus.Winning ? 'bg-altWinning' : ''} ${
              b.status === TicketStatus.Open ? 'bg-white' : ''
            }`}
          >
            {b.odd}
          </span>
          {b.status === TicketStatus.Losing && (
            <span className='h-9 w-10 z-0 inline-flex items-center justify-center bg-losing rounded-r -ml-1'>
              <WhiteXIcon />
            </span>
          )}
          {b.status === TicketStatus.Winning && (
            <span className='h-9 w-10 z-0 inline-flex items-center justify-center bg-winning  rounded-r -ml-1'>
              <WhiteCheckedIcon />
            </span>
          )}
        </div>
      ))}
    </article>
  );
};
