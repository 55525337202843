// Definitions
export interface VirtualGameTicket {
  id: string;
  ticketDateTime: number;
  ticketDateTimeUTC: number;
  status: {
    id?: string;
    value: string;
    name: string;
  };
  product: string;
  payin: number;
  payinTax: number;
  cancelledPayinTax: null;
  bet: number;
  winnings: number;
  payoutTax: number;
  possiblePayoutTax: number;
  possiblePayout: number;
  payout: number;
  cancelledAmount: number | null;
  payedOutAt: number | null;
  payedOutAtUTC: number | null;
  maxPossibleWin: number | null;
  maxPossiblePayoutTax: number | null;
  bets: {
    id: string | null;
    status: {
      id: string;
      value: string;
      name: string;
    };
    type: number;
    typeValue: string;
    category: string;
    value: string;
    amount: number;
    winnings: number;
    cancelledAmount: number | null;
    eventId: number;
    realEventId: number;
    bonus: number | null;
    odd: number | null;
    eventValue: string;
    possibleWinnings: number;
    eventDateTime: number;
    eventDateTimeUTC: number;
    tax: number;
    possiblePayout: number;
    payin: number | null;
    [key: string]: any;
  }[];
  responseMessage: string | null;
  [key: string]: any;
}

export enum TicketStatus {
  Open,
  Losing,
  Winning,
}

export enum VirtualGameProduct {
  Dogs = 'Curse de caini',
  LuckySix = 'Lucky Six',
  Live = 'Live',
  Unknown = '-',
}
export interface NormalizedTicketResult {
  id: string;
  game: VirtualGameProduct;
  createdUTC: number;
  status: TicketStatus;
  bet: number;
  payIn: number;
  payInTax: number;
  maxPossibleWin?: number;
  maxPossibleTax?: number;
  winnings: number;
  luckySixBets?: LuckySixBet[];
  dogsBets?: DogsBet[];
  liveBets?: LiveBet[];
  liveBetTitle?: string;
}

export interface LiveBet {
  status: TicketStatus;
  matchName: string;
  betName: string;
  betOutcome: string;
  odd: number;
  matchDateTimeUTC: number;
}

export interface LuckySixBet {
  status: TicketStatus;
  eventDateTimeUTC: number;
  round: number;
  value: string[];
  results: string[];
  specialTypeValue?: string;
  specialValue?: string;
  specialResult?: string;
  possibleWinnings: number;
  winnings: number;
  type: number;
  typeValue: string;
  category: string;
  payIn?: number;
  amount: number;
  hasNumbers: boolean;
}
export interface DogsBetCombination {
  value: string;
  odd: number;
  amount: number;
  possibleWin: number;
  status: TicketStatus;
}
export interface DogsBet {
  status: TicketStatus;
  eventDateTimeUTC: number;
  round: number;
  amount: number;
  title: string; //castigator, primii 2 in orice ordine
  combinations: DogsBetCombination[];
  results: string[];
  possibleWinnings: number;
  winnings: number;
  type: number;
  payIn?: number;
}
