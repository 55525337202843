import React, { MouseEventHandler } from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { RootState, OfferActions } from '../store';

import Bet from './Bet';

interface OwnProps {
  tid: string;
  categoryName: string;
}

const TournamentTable: React.FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const { tournamentName, odds, categoryName, betsIds, tid, showDetails } =
    props;

  const onShowDetails = (e: React.SyntheticEvent<HTMLDivElement>) => {
    showDetails();
  };

  const tooManyOdds: boolean = odds.length > 6;
  const dateAndTime = tooManyOdds ? null : (
    <>
      <div className='t-tdate'>mai multe</div>
      <div className='t-tdate'>DATA</div> <div className='t-tdate'>ORA</div>
    </>
  );

  return (
    <React.Fragment key={tid}>
      <div className='t-hc'>
        <div className='t-h' data-tid={tid}>
          <div className='t-title' onClick={onShowDetails}>
            {categoryName}
            <span> &gt; </span>
            {tournamentName}
          </div>

          {odds.slice(0, 9).map((odd, i) => (
            <b className='t-oname' key={i}>
              {odd}
            </b>
          ))}
          <div className='inline-block flex-grow flex-shrink flex-auto'>
            &nbsp;
          </div>
          {dateAndTime}
        </div>

        {betsIds.length > 0 && (
          <Bet
            key={betsIds[0]}
            b_id={betsIds[0]}
            onClick={onShowDetails}
            more={`+ ${Math.ceil(7 + Math.random() * 151)}`}
          />
        )}
      </div>

      {betsIds.map((b, bi) =>
        bi > 0 ? (
          <Bet
            key={b}
            b_id={b}
            onClick={onShowDetails}
            more={`+ ${Math.ceil(7 + Math.random() * 151)}`}
          />
        ) : null
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const { offer } = state;
  const { tid } = ownProps;
  const { selectedDate } = offer;
  const tournament = offer.tournaments[tid];
  const types = tournament.typesByDate[selectedDate!];

  if (types === undefined) {
    console.error('types is undefined');
    console.log(
      'date: ',
      selectedDate,
      'tid: ',
      tid,
      ' tournament:',
      tournament.typesByDate[selectedDate!]
    );
  }
  if (types.length === 0) {
    console.error('types is empty');
    console.log(
      'date: ',
      selectedDate,
      'tid: ',
      tid,
      ' tournament:',
      tournament.typesByDate[selectedDate!]
    );
  }
  const typeInfo = types[0];
  // fix lovitura de incepere

  return {
    tid: ownProps.tid,
    tournamentName: offer.tournaments[tid].name,
    odds: offer.types[typeInfo.id].odds,
    categoryName: ownProps.categoryName,
    betsIds: typeInfo.bets,
  };
};

const mapDispatchActions = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    showDetails: (bet_id?: string) => {
      dispatch(OfferActions.showTournamentDetails(ownProps.tid, bet_id));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchActions);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TournamentTable);
