// middleware/middleware.js

import * as actions from '../system/actions';
import { updateConfig } from '../settings/actions';
import { ActionTypes } from '../system/types';

import { RootState } from '..';
import { reloadApp } from '../../hardware';
import { AppConfiguration } from '../settings/types';

const socketMiddleware = () => {
  let socket: WebSocket | undefined;
  let ws_url = '';
  const onOpen = (store: any) => (event: WebSocketEventMap['open']) => {
    // console.log('websocket open ', event);
    store.dispatch(actions.wsConnected());
  };

  const sendMessage = (msg: string) => {
    if (socket?.readyState === WebSocket.OPEN) {
      try {
        socket?.send(JSON.stringify({ type: 'MSG', message: msg }));
      } catch (ex) {
        console.warn('ws send exception: ', ex);
      }
    }
  };

  const onClose = (store: any) => (event: WebSocketEventMap['close']) => {
    const ev = event as CloseEvent;
    if (ev.code !== 4001) {
      setTimeout(() => {
        store.dispatch(actions.wsConnect(ws_url));
      }, 3000);
    } else {
      console.log('ws closed, code: ', ev.code, ' reason: ', ev.reason);
      console.error('not reconnecting..');
    }
    store.dispatch(actions.wsDisconnected());
  };

  const onError = (store: any) => (event: WebSocketEventMap['error']) => {
    const ev = event as ErrorEvent;
    console.error('websocket error, ', event);
    store.dispatch(actions.wsDisconnected(ev.error));
  };

  const onMessage =
    (store: any) => async (event: WebSocketEventMap['message']) => {
      // console.log('ws message: ', event);
      const payload = JSON.parse(event.data);
      // console.log('received server message ', payload);

      switch (payload.type) {
        case 'updateConfig': {
          try {
            const config = payload.config as AppConfiguration;
            store.dispatch(updateConfig(config));
          } catch (error) {
            console.error(`Error updating config: ${error}`);
          }
          break;
        }
        case 'reload': {
          reloadApp();
          break;
        }

        case 'reset_reload': {
          reloadApp();
          break;
        }
        case 'debug': {
          const reduxStore = store as RootState;
          sendMessage(
            JSON.stringify({
              type: 'debug',
              uuid: reduxStore.settings!.identity._id,
              isTouchscreen: true,
              isCheckingTicket: reduxStore.system.isCheckingTicket,
            })
          );
          break;
        }

        default:
          break;
      }
    };

  // the middleware part of this function
  return (store: any) => (next: any) => (action: ActionTypes) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== undefined) {
          socket.onclose = null;
          socket.close(4001);
        }
        // connect to the remote host
        ws_url = action.meta.url;
        try {
          socket = new WebSocket(ws_url);
          // websocket handlers
          socket.onmessage = onMessage(store);
          socket.onclose = onClose(store);
          socket.onopen = onOpen(store);
          socket.onerror = onError(store);
        } catch (ex) {
          console.error('WS error creating websocket ', ex);
        }

        break;
      case 'WS_DISCONNECT':
        if (socket !== undefined) {
          socket.close(4001, 'not reconnecting.');
        }
        socket = undefined;
        // console.debug('websocket closed');
        break;
      case 'WS_MESSAGE':
        sendMessage(JSON.stringify(action.meta.message));

        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
