export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export interface PlaylistInfo {
  _id: string;
  name: string;
  video: {
    video_id: string;
    originalname: string;
    size: number;
    url: string;
  };
  [key: string]: any;
}
export interface AppConfiguration {
  ticketsAPI: string;
  wssAPI: string;
  retailAppAPI?: string;
  printingAPI?: string;
  printingMode: string;
  printerHostname: string | undefined;
  playlist?: PlaylistInfo;
  idleTimeoutMovie: number;
}

export interface AppIdentity {
  _id: string;
  code: string;
  shop: string;
  brand: string;
  test: boolean;
}

export interface AppAuth {
  _id: string;
  token: string;
}

export interface SettingsState {
  identity: AppIdentity;
  auth: AppAuth;
  config: AppConfiguration;
}
export type Settings = SettingsState;

export const initialState = null;

interface UpdateConfig {
  type: typeof UPDATE_CONFIG;
  meta: {
    config: AppConfiguration;
  };
}
interface SetSettings {
  type: typeof SET_SETTINGS;
  meta: {
    settings: SettingsState;
  };
}

interface UpdateToken {
  type: typeof UPDATE_TOKEN;
  meta: {
    token: string;
  };
}

export type ActionTypes = UpdateConfig | SetSettings | UpdateToken;
