/** @format */

// import { v4 } from 'uuid';
import produce, { Draft } from 'immer';
import { UPDATE_TICKETS } from '../ticket/ticket-types';

import {
  ActionTypes,
  OfferState,
  RESET_OFFER,
  OfferDay,
  UPDATE_TODAY,
  CHANGE_SELECTED_DATE,
  CHANGE_SELECTED_CATEGORY,
  SHOW_TOURNAMENT_DETAILS,
  HIDE_TOURNAMENT_DETAILS,
  SelectedOfferData,
  UPDATE_SELECTED_ODDS,
  SELECT_PENDING_ODD,
} from './types';

export const initialState: OfferState = {
  categories: {},
  tournaments: {},
  types: {},
  bets: {},
  days: {},
  daysIds: [],
  updated: 0,
  v: 0,
  selectedDate: undefined,
  selectedCategory: undefined,
  selectedTournament: undefined,
  selectedBet: undefined,
  selectedOffer: undefined,
  prevSelectedOdds: [],
  prevSelectedBetIds: [],
};
const idOfDate = (date: Date): string => {
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  return `${yyyy}${mm < 10 ? `0${mm}` : mm}${dd < 10 ? `0${dd}` : dd}`;
};

export const offerReducer = (
  state = initialState,
  action: ActionTypes
): OfferState => {
  switch (action.type) {
    default:
      return state;
    case CHANGE_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.meta.date,
        selectedCategory: action.meta.category,
        selectedOffer: dataSelector(
          state,
          action.meta.date,
          action.meta.category
        ),
      };

    case CHANGE_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.meta.category,
        selectedOffer: dataSelector(
          state,
          state.selectedDate,
          action.meta.category
        ),
      };
    case SHOW_TOURNAMENT_DETAILS:
      return {
        ...state,
        selectedTournament: action.meta.tournament_id,
        selectedBet: action.meta.bet_id,
      };
    case HIDE_TOURNAMENT_DETAILS:
      return {
        ...state,
        selectedTournament: undefined,
        selectedBet: undefined,
      };

    case RESET_OFFER:
      return produce(state, (draft) => {
        const DayName = ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sam'];
        const MonthName = [
          'Ian',
          'Feb',
          'Mar',
          'Apr',
          'Mai',
          'Iun',
          'Iul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];

        const daysMap: { [key: string]: OfferDay } = {};
        action.meta.offer.days.forEach((day: string) => {
          const d = action.meta.offer.daysMap[day] as OfferDay;
          const yyyy = Number(day.substr(0, 4));
          const mm = Number(day.substr(4, 2));
          const dd = Number(day.substr(6, 2));

          const date = new Date(yyyy, mm - 1, dd);

          d.day = DayName[date.getDay()];
          d.date = `${date.getDate()} ${MonthName[date.getMonth()]}`;

          d.ts = date.getTime();
          daysMap[d.id] = d;
        });
        draft.categories = action.meta.offer.categories;
        draft.tournaments = action.meta.offer.tournaments;
        draft.types = action.meta.offer.types;
        draft.bets = action.meta.offer.bets;
        draft.daysIds = action.meta.offer.days;
        draft.days = daysMap;
        draft.updated = action.meta.offer.update;
        draft.v += 1;
        draft.selectedDate = undefined;
        draft.selectedCategory = undefined;
        draft.selectedTournament = undefined;
        draft.selectedBet = undefined;
        draft.selectedOffer = undefined;
        draft.prevSelectedOdds = [];
        draft.prevSelectedBetIds = [];
      });
    case UPDATE_TODAY: {
      const now = new Date();
      return setCurrentDate(state, now);
    }

    case SELECT_PENDING_ODD: {
      const { code, bid, oid, pending } = action.meta;
      if (undefined === bid || undefined === oid) {
        return state;
      }

      return produce(state, (draft) => {
        if (draft.bets[bid]) {
          draft.bets[bid].odds.forEach((odd, oix) => {
            if (odd.id === oid) {
              odd.p = pending;
            }
            draft.bets[bid].odds[oix] = odd;
          });
        }
      });
    }

    case UPDATE_SELECTED_ODDS: {
      const { betIds, oids } = action.meta;
      const prevBetIds = state.prevSelectedBetIds ?? [];
      const allAffectedBets = betIds.concat(prevBetIds);

      return produce(state, (draft) => {
        draft.prevSelectedBetIds = betIds;
        draft.prevSelectedOdds = oids;
        allAffectedBets.forEach((bid: string) => {
          if (draft.bets[bid]) {
            draft.bets[bid].odds.forEach((odd, oix) => {
              odd.sel = oids.indexOf(odd.id) >= 0;
              draft.bets[bid].odds[oix] = odd;
            });
          }
        });
      });
    }
  }
};

const setCurrentDate = produce((draft: Draft<OfferState>, date: Date) => {
  const dateNow = date;

  const dateNowId = idOfDate(dateNow);
  const dateNowTS = dateNow.getTime();
  const dateNowTS3H = dateNowTS + 3 * 3600 * 1000;
  const dateNowTS6H = dateNowTS + 6 * 3600 * 1000;
  const dateNowTS12H = dateNowTS + 12 * 3600 * 1000;

  let i = 0;
  const hours: OfferDay[] = [3, 6, 12].map((d) => {
    return {
      id: `+${d}`,
      day: `+${d} h`,
      date: '',
      tournaments: [],
      categories: [],
      bets: [],
      ts: dateNowTS + d * 3600 * 1000,
    };
  });

  while (i < draft.daysIds.length) {
    const id = draft.daysIds[i];

    if (id < dateNowId) {
      delete draft.days[id];
      draft.daysIds.splice(i, 1);
    } else if (id === dateNowId) {
      i++;
      const todayOffer = draft.days[dateNowId];
      draft.days[id].day = 'Astăzi';
      draft.days[id].date = '';

      hours.forEach((h) => {
        h.categories = [...todayOffer.categories];
      });

      let c = 0;
      while (c < todayOffer.categories.length) {
        const cid = todayOffer.categories[c];
        const category = draft.categories[cid];
        const tournaments = category.tournamentsByDate[dateNowId];

        hours.forEach((h) => {
          category.tournamentsByDate[h.id] = [...tournaments];
        });

        let t = 0;
        while (t < tournaments.length) {
          const tid = tournaments[t];
          const tournament = draft.tournaments[tid];
          const types = tournament.typesByDate[dateNowId];
          hours.forEach((h) => {
            tournament.typesByDate[h.id] = [...types];
          });
          let z = 0;
          while (z < types.length) {
            const type = types[z];
            // check bets
            const betsByHours: number[] = [0, 0, 0];
            let b = 0;
            while (b < type.bets.length) {
              const betid = type.bets[b];
              const bet = draft.bets[betid];

              if (!bet || bet.ts < dateNowTS) {
                type.bets.splice(b, 1);
                delete draft.bets[betid];
              } else {
                b++;

                if (bet.ts < dateNowTS12H) {
                  betsByHours[2] += 1;
                }
                if (bet.ts < dateNowTS6H) {
                  betsByHours[1] += 1;
                }
                if (bet.ts < dateNowTS3H) {
                  betsByHours[0] += 1;
                }
              }
            }

            [0, 1, 2].forEach((ix) => {
              if (betsByHours[ix] === 0) {
                const hid = hours[ix].id;
                const tix = tournament.typesByDate[hid].findIndex(
                  (tt) => tt.id === type.id
                );
                tournament.typesByDate[hid].splice(tix, 1);
              }
            });

            if (type.bets.length === 0) {
              types.splice(z, 1);
            } else {
              z++;
            }
          }

          [0, 1, 2].forEach((ix) => {
            const hid = hours[ix].id;
            const t = tournament.typesByDate[hid];
            if (t.length === 0) {
              const tix = category.tournamentsByDate[hid].findIndex(
                (t) => t === tid
              );
              category.tournamentsByDate[hid].splice(tix, 1);
              delete tournament.typesByDate[hid];
            }
          });

          if (types.length === 0) {
            tournaments.splice(t, 1);
            delete tournament.typesByDate[dateNowId];
          } else {
            t++;
          }
        }

        [0, 1, 2].forEach((ix) => {
          const hid = hours[ix].id;
          const t = draft.categories[category.id].tournamentsByDate[hid];
          if (t.length === 0) {
            const cix = hours[ix].categories.indexOf(`${category.id}`);

            hours[ix].categories.splice(cix, 1);

            delete draft.categories[category.id].tournamentsByDate[hid];
          }
        });

        if (tournaments.length === 0) {
          todayOffer.categories.splice(c, 1);
          delete draft.categories[category.id].tournamentsByDate[todayOffer.id];
        } else {
          c++;
        }
      }
    } else {
      i++;
    }
  }
  if (draft.daysIds[0] === dateNowId) {
    draft.daysIds.shift();
  }

  draft.daysIds.unshift(dateNowId, ...['+3', '+6', '+12']);
  hours.forEach((h) => {
    draft.days[h.id] = h;
  });

  if (!draft.days[dateNowId]) {
    draft.days[dateNowId] = {
      id: dateNowId,
      day: `Astăzi`,
      date: '',
      tournaments: [],
      categories: [],
      bets: [],
      ts: dateNowTS,
    };
  }

  // fix selected items
  const { selectedDate } = draft;
  if (selectedDate) {
    if (!draft.days[selectedDate]) {
      draft.selectedDate = draft.daysIds[0];
      draft.selectedCategory = draft.days[draft.selectedDate].categories[0];
      if (
        draft.days[draft.selectedDate].categories.findIndex(
          (c) => `${c}` === '3'
        ) >= 0
      ) {
        draft.selectedCategory = '3';
      }
      draft.selectedTournament = undefined;
      draft.selectedBet = undefined;
    } else {
      const { selectedCategory } = draft;
      if (selectedCategory) {
        if (draft.days[selectedDate].categories.indexOf(selectedCategory) < 0) {
          draft.selectedCategory = draft.days[selectedDate].categories[0];
          if (
            draft.days[selectedDate].categories.findIndex(
              (c) => `${c}` === '3'
            ) >= 0
          ) {
            draft.selectedCategory = '3';
          }
          draft.selectedTournament = undefined;
          draft.selectedBet = undefined;
        } else {
          const tid = draft.selectedTournament;
          if (tid) {
            if (!draft.tournaments[tid].typesByDate[selectedDate]) {
              draft.selectedTournament = undefined;
              draft.selectedBet = undefined;
            } else {
              const betid = draft.selectedBet;
              if (betid && !draft.bets[betid]) {
                draft.selectedBet = undefined;
              }
            }
          }
        }
      }
    }
  }
});

const dataSelector = (
  state: OfferState,
  date: string | undefined,
  category: string | undefined
): SelectedOfferData => {
  // console.time('data');
  const selectedCategoryId = category;

  if (selectedCategoryId === undefined || date === undefined) {
    return { updated: new Date(), tournaments: [] };
  }

  const t_ids = state.categories[selectedCategoryId].tournamentsByDate[date];
  const tournaments = t_ids.map((t) => state.tournaments[t]);

  const tournaments_data = tournaments.map((tournament) => {
    const main_type_info = tournament.typesByDate[date][0];
    const type = state.types[main_type_info.id];
    const bets = main_type_info.bets;
    return {
      id: tournament.id,
      name: tournament.name,
      type,
      bets,
      totalBets: bets.length,
    };
  });
  // console.timeEnd('data');
  return { updated: new Date(), tournaments: tournaments_data };
};
