/** @format */

import {
  RESET_OFFER,
  ActionTypes,
  OfferData,
  UPDATE_TODAY,
  CHANGE_SELECTED_DATE,
  CHANGE_SELECTED_CATEGORY,
  SHOW_TOURNAMENT_DETAILS,
  HIDE_TOURNAMENT_DETAILS,
  UPDATE_SELECTED_ODDS,
  SELECT_PENDING_ODD,
} from './types';

export function selectPendingOdd(
  bid: string | undefined,
  code: string | undefined,
  oid: string | undefined,
  pending: boolean
): ActionTypes {
  return {
    type: SELECT_PENDING_ODD,
    meta: {
      code,
      bid,
      oid,
      pending,
    },
  };
}

export function resetOffer(offer: OfferData): ActionTypes {
  return {
    type: RESET_OFFER,
    meta: {
      offer,
    },
  };
}

export function updateToday(): ActionTypes {
  return {
    type: UPDATE_TODAY,
  };
}

export function changeSelectedDate(
  date: string,
  category: string
): ActionTypes {
  return {
    type: CHANGE_SELECTED_DATE,
    meta: {
      date,
      category,
    },
  };
}
export function changeSelectedCategory(category: string): ActionTypes {
  return {
    type: CHANGE_SELECTED_CATEGORY,
    meta: {
      category,
    },
  };
}

export function showTournamentDetails(
  tournament_id: string,
  bet_id?: string
): ActionTypes {
  return {
    type: SHOW_TOURNAMENT_DETAILS,
    meta: {
      tournament_id,
      bet_id,
    },
  };
}

export function hideTournamentDetails(): ActionTypes {
  return {
    type: HIDE_TOURNAMENT_DETAILS,
  };
}

export function updateSelectedOdds(
  betIds: string[],
  oids: string[]
): ActionTypes {
  return {
    type: UPDATE_SELECTED_ODDS,
    meta: {
      betIds: betIds,
      oids: oids,
    },
  };
}
